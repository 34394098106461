import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import Cookies from "js-cookie";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import AppContext from "../../context/AppContext";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import translations from "../common/Translations";
import {fetchTasks} from "../../service/Task"
export default function FieldListModal({
  open,
  setOpen,
  selectedtask,
  setIsChecked,
  taskData,
  setTaskData,
  selectedOrgId,
  resetFilters,
}) {
  const context = useContext(AppContext);
  const { workflowId, setSelectAll, userType ,setPriority,setActive } =
    context;
  const [fieldAgent, setFieldAgent] = useState();
  const [selectedfieldAgent, setselectedfieldAgent] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // New state variable
  const cancelButtonRef = useRef(null);
  const { theme } = useContext(AppContext);
  const navigate = useNavigate();
  useEffect(() => {}, [fieldAgent]);

  useEffect(() => {
    if (open && !isDataFetched) {
      const fetchData = (selectedOrgId) => {
        const token = Cookies.get("accessToken");
        let organizationQuery = "";
        if (userType === "super_admin" && selectedOrgId) {
          organizationQuery = `&organizationId=${selectedOrgId}`;
        }
        axios
          .get(
            `${process.env.REACT_APP_BASE_BACKEND_API_URL}api/v1/user/field-agents?${organizationQuery}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            if (response.data.body.value) {
              setFieldAgent(response.data.body.value);
            } else {
              setError("No field agents found");
            }
            setIsDataFetched(true);
          })
          .catch((error) => {
            console.error("An error occurred:", error);
          });
      };
      fetchData(selectedOrgId);
    }
    if (!open) {
      setIsDataFetched(false);
    }
  }, [open, selectedOrgId, isDataFetched]);

  const handleFieldAgentSelect = (userId) => {
    setselectedfieldAgent((prevAgents) => {
      let updatedAgents;
      if (prevAgents.includes(userId)) {
        updatedAgents = prevAgents.filter((agentId) => agentId !== userId); // Deselect
      } else {
        updatedAgents = [...prevAgents, userId]; // Select
      }
      console.log("Updated Field Agents: ", updatedAgents); // Log updated list
      return updatedAgents;
    });
  };

  // const assignTasks = async (e) => {
  //   if (isSubmitting) return;
  //   setIsSubmitting(true);
  //   const temp = JSON.stringify({
  //     taskIdList: selectedtask,
  //     fieldAgentIdList: selectedfieldAgent,
  //     workflowId: workflowId,
  //   });
  //   const token = Cookies.get("accessToken");
  //   fetch(
  //     `${process.env.REACT_APP_BASE_BACKEND_API_URL}api/v1/task/multi-assign`,
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: temp,
  //     }
  //   )
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setIsChecked(false);
  //       setSelectAll(false);
  //       setOpen(false);
  //       toast.success("Field agent assigned");

  //       const updatedTaskData = taskData.map((task) => {
  //         if (selectedtask.includes(task.taskId)) {
  //           // Find the first selected field agent details
  //           const assignedAgent = fieldAgent.find((agent) =>
  //             selectedfieldAgent.includes(agent.userId)
  //           );

  //           return {
  //             ...task,
  //             taskStatus: "Assigned", // Update task status
  //             isChecked: false, // Uncheck the task
  //             assignees: [
  //               {
  //                 assigneeId: assignedAgent?.userId || null,
  //                 assigneeName: assignedAgent?.name || "Unknown",
  //               },
  //             ], // Update with the new assignee details
  //           };
  //         }
  //         return task; // No change for other tasks
  //       });
  //       setTaskData(updatedTaskData);
      
  //       fetchTasks()
  //       setselectedfieldAgent([])
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     })
  //     .finally(() => {
  //       setTimeout(() => {
  //         setIsSubmitting(false);
  //       }, 1000);
  //     });
  // };


  const assignTasks = async (e) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    const temp = JSON.stringify({
      taskIdList: selectedtask,
      fieldAgentIdList: selectedfieldAgent,
      workflowId: workflowId,
    });
    const token = Cookies.get("accessToken");
    fetch(
      `${process.env.REACT_APP_BASE_BACKEND_API_URL}api/v1/task/multi-assign`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: temp,
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setIsChecked(false);
        setSelectAll(false);
        setOpen(false);
        toast.success("Field agent assigned");
  
        // const updatedTaskData = taskData.map((task) => {
        //   if (selectedtask.includes(task.taskId)) {
        //     // Find the first selected field agent details
        //     const assignedAgent = fieldAgent.find((agent) =>
        //       selectedfieldAgent.includes(agent.userId)
        //     );
  
        //     return {
        //       ...task,
        //       isChecked: false, // Uncheck the task
        //       assignees: [
        //         {
        //           assigneeId: assignedAgent?.userId || null,
        //           assigneeName: assignedAgent?.name || "Unknown",
        //         },
        //       ], // Update with the new assignee details
        //     };
        //   }
        //   return task; // No change for other tasks
        // });
        // setTaskData(updatedTaskData);
  
        // Trigger fetchTasks after task assignment
        fetchTasks({
          workflowId,
          currentPage: 1,
          pageSize: 10,
          priority: "Priority",
          active: "Status",
        })
        .then((fetchedTasks) => {
          console.log("Fetched tasks:", fetchedTasks);
          // Update taskData with the new fetched tasks
          setTaskData(fetchedTasks.taskList);
          setselectedfieldAgent([]);
          setActive("Status")
          setPriority("Priority")
        })
        .catch((error) => {
          console.error("Error fetching tasks:", error);
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setTimeout(() => {
          setIsSubmitting(false);
        }, 1000);
      });
  };
  
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity `}
          />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg bg-white text-left
               shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ${
                 theme === "dark"
                   ? "dark-mode "
                   : theme === "high-contrast"
                   ? "high-contrast  "
                   : ""
               }`}
              >
                <div
                  className={`bg-white rounded-lg shadow  ${
                    theme === "dark"
                      ? "dark-mode "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  }`}
                >
                  <div className="flex items-center gap-10 p-2 md:p-5  rounded-t dark:border-gray-600">
                    <h3 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Field Staff List
                    </h3>
                    <button
                      onClick={assignTasks}
                      type="button"
                      disabled={isSubmitting}
                      className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-lg hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      {isSubmitting ? "Assigning..." : "Assign"}
                    </button>
                    {/* <button
                      type="button"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                      className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-lg hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      {translations[langMode].reassign}
                    </button> */}
                    <button
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      data-modal-hide="small-modal"
                      onClick={() => setOpen(false)}
                    >
                      <RxCross2 />
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  <div className="p-2 md:p-5 space-y-4 overflow-y-auto h-auto max-h-[210px] ">
                    {error ? (
                      <div className="text-red-500">{error}</div>
                    ) : (
                      fieldAgent &&
                      fieldAgent.map((agent, index) => (
                        <div
                          className={`bg-white p-3 border border-gray-300 mt-2 
                            ${
                              theme === "dark"
                                ? "dark-mode "
                                : theme === "high-contrast"
                                ? "high-contrast  "
                                : ""
                            }`}
                        >
                          <div className="flex items-center">
                            <input
                              id="default-radio-1"
                              type="checkbox"
                              value=""
                              name="default-radio"
                              onClick={() =>
                                handleFieldAgentSelect(agent.userId)
                              }
                              checked={selectedfieldAgent.includes(
                                agent.userId
                              )}
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              for="default-radio-1"
                              className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                              {agent.name}
                            </label>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
