import React, { useContext, useEffect, useState } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { CgExport, CgFileDocument } from "react-icons/cg";
import { IoSearch } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import DateRangePicker from "../common/DateRangePicker";
import AppContext from "../../context/AppContext";
import { fetchOrganizations } from "../../service/Report";
import { getScheduleReport } from "../../service/Worklog";
import Joyride from "react-joyride";
import ExportData from "../../pages/worklog/ExportData";
import translations from "../common/Translations";

const WorkLogToolbar = ({
  setOpen,
  setSelectedPriority,
  setDates,
  setSearchAccountNumber,
}) => {
  const navigate = useNavigate();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const { setgetScheduleReportDetails } = useContext(AppContext);
  const { theme, langMode, runTour, workLogtoolbar } = useContext(AppContext);
  const [openModal, setOpenModal] = useState(false);
  const [inputValue, setInputValue] = useState(""); // Temporary state for input

  const priorityoptions = [
    { id: "Low_Priority", value: "Low Priority" },
    { id: "Medium_Priority", value: "Medium priority" },
    { id: "High_Priority", value: "High Priority" },
  ];
  const handlePriorityChange = (e) => {
    setSelectedPriority(e.target.value);
  };

  const handleDateSelect = (fromDate, toDate) => {
    setDates({
      fromDate: fromDate ? new Date(fromDate).toISOString() : null,
      toDate: toDate ? new Date(toDate).toISOString() : null,
    });
  };
  const GoExport = () => {
    setOpenModal(true);
  };

  const GoExportPDF = () => {
    Cookies.set("viewMode", "worklog");
    navigate("/pdf-generator");
  };
  const closeDatePopup = () => {
    if (showDatePicker === true) {
      setShowDatePicker(false);
    }
  };
  const handleSearchChange = (e) => {
    setSearchAccountNumber(e.target.value);
  };


    const handleSearch = () => {
      setSearchTerm(inputValue);
      setSearchAccountNumber(inputValue); // Set the final value on search
    };
  
    const handleInputChange = (event) => {
      setInputValue(event.target.value); // Update temporary state
    };
  
     useEffect(() => {
        if (inputValue.length === 0) {
          handleSearch();
        }
      }, [inputValue]);
  

  const getScheduleMailReport = async () => {
    try {
      const data = await getScheduleReport();
      setgetScheduleReportDetails(data);
    } catch (error) {
      console.error("An error occurred:", error);
    }
    setOpen(true);
  };
  const handleExportReport =()=>{
    navigate('/export')
  }
  return (
    <>
      <Joyride
        steps={workLogtoolbar}
        run={runTour}
        continuous
        showSkipButton
        showProgress
        scrollToFirstStep
        styles={{
          options: {
            arrowColor: "#fff",
            zIndex: 10000,
            overlayColor: "rgba(0, 0, 0, 0.5)",
            primaryColor: "#4C3BCF",
            textColor: "#333",
            zIndex: 1000,
          },
          tooltip: {
            borderRadius: "10px",
          },
          buttonSkip: {
            color: "#4C3BCF",
          },
          buttonNext: {
            backgroundColor: "#4C3BCF",
          },
          buttonBack: {
            color: "#4C3BCF",
          },
        }}
      />
      <div className="flex flex-col lg:flex-row md:justify-between items-center space-y-4 md:space-y-0">
        <div className="flex flex-col md:flex-row md:items-center gap-2 w-full md:w-auto">
          <div
            className={`pb-4 bg-white w-full md:w-auto ${
              theme === "dark"
                ? "dark-mode"
                : theme === "high-contrast"
                ? "high-contrast"
                : ""
            }`}
          >
            {/* <div className="relative mt-5">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <IoSearch />
              </div>
              <input
                type="text"
                id="table-search"
                className={`block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-36
           focus:ring-blue-300 focus:border-blue-300 dark:border-gray-600
            dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
              theme === "dark"
                ? "dark-mode"
                : theme === "high-contrast"
                ? "high-contrast"
                : ""
            }`}
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div> */}


<div className="flex">
              <div className="relative mt-1">
                {/* <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                  <IoSearch />
                </div> */}
                <input
                  id="account-search"
                  autoComplete="new-password" // Alternative to prevent autofill
                  type="text"
                  className={`block h-9 pt-2 mt-4  text-sm text-gray-900 border border-gray-300 rounded-l-md  w-36
                   focus:ring-blue-300 focus:border-blue-300  
                    ${
                      theme === "dark"
                        ? "dark-mode  "
                        : theme === "high-contrast"
                        ? "high-contrast  "
                        : ""
                    }`}
                  placeholder={translations[langMode].search}
                  value={inputValue} // Bind temporary state to input
                  onChange={handleInputChange} // Update temporary state on input change
                />
              </div>

              <button
                onClick={handleSearch} // Update state on button click
                className="h-9 px-4 mt-5 bg-blue-800 text-white rounded-r-md hover:bg-blue-600"
              >
                <IoSearch />{" "}
              </button>
            </div>

          </div>
          <React.Fragment>
            <select
              onChange={handlePriorityChange}
              onClick={closeDatePopup}
              className={`w-full h-9 mt-1 text-base border-gray-300 focus:outline-none focus:ring-purple-500
         focus:border-indigo-500 sm:text-sm rounded-md ${
           theme === "dark"
             ? "dark-mode"
             : theme === "high-contrast"
             ? "high-contrast"
             : ""
         }`}
            >
              <option selected disabled>
                {translations[langMode].priority}
              </option>
              {priorityoptions.map((priorityoptions) => (
                <option key={priorityoptions.id} value={priorityoptions.id}>
                  {priorityoptions.value}
                </option>
              ))}
            </select>
            <DateRangePicker
              onDateSelect={handleDateSelect}
              showDatePicker={showDatePicker}
              setShowDatePicker={setShowDatePicker}
            />
          </React.Fragment>
        </div>
        <div className="flex flex-wrap justify-center md:justify-start space-x-2 mt-4 md:mt-0">
          <button
            type="button"
            className="shecduleemail px-3 py-2 text-xs font-medium inline-flex items-center text-white bg-gray-800 rounded-lg hover:bg-gray-700 focus:ring-1 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
            onClick={getScheduleMailReport}
          >
            <span className="bg-white rounded-full p-1 mr-1">
              <AiOutlineMail className="w-4 h-4  text-gray-800 " />
            </span>
            {translations[langMode].email}
          </button>
          <button
            type="button"
            onClick={handleExportReport}
            className="exportreport px-3 py-2 text-xs font-medium inline-flex items-center text-white bg-blue-800 rounded-lg
             hover:bg-blue-900 focus:ring-1 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-900
              dark:focus:ring-blue-800">
            <span className="bg-white rounded-full p-1 mr-1">
              <CgExport className="w-4 h-4  text-gray-800 " />
            </span>
            {translations[langMode].export}
          </button>
          <button
            type="button"
            onClick={GoExportPDF}
            className="exportpdf px-3 py-2 text-xs font-medium inline-flex items-center text-white bg-yellow-400 rounded-lg hover:bg-yellow-500 focus:ring-1 focus:outline-none focus:ring-yellow-600 dark:bg-yellow-600 dark:hover:bg-yellow-900 dark:focus:ring-yellow-800"
          >
            <span className="bg-white rounded-full p-1 mr-1">
              <CgFileDocument className="w-4 h-4 text-yellow-500 " />
            </span>
            {translations[langMode].pdf}
          </button>
        </div>
      </div>
      {/* <ExportData setOpenModal={setOpenModal} openModal={openModal} /> */}
    </>
  );
};

export default WorkLogToolbar;
