import React, { useContext, useEffect, useState, useCallback } from "react";
import { LayersControl, MapContainer, TileLayer, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-draw";
import AppContext from "../../context/AppContext";
import {
  fetchFieldAgentMap,
  multiassignTasks,
  assignTasks,
  unassignTask,
} from "../../service/Task";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import { getTaskDetails } from "../../service/Task";
import MarkerInfoModal from "./MarkerInfoModal/MarkerInfoModal";
import StatusFilter from "./StatusFilter";
import MapViewToolbar from "./MapViewToolbar";
import FieldAgentList from "./FieldAgentList/FieldAgentList";
import { Spinner } from "../../Spinner";
import { IoCheckmarkDone } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import ReactDOMServer from "react-dom/server";
import { getTaskAPI } from "../../service/mapView";
import { FaLocationDot } from "react-icons/fa6";
import translations from "../common/Translations";

const ResetZoom = ({ workflowId, taskStatus }) => {
  const map = useMap();

  useEffect(() => {
    if (workflowId || taskStatus) {
      map.setZoom(5);
    }
  }, [map, workflowId, taskStatus]);

  return null;
};

const getCombinedMarkerIcon = (taskStatus, taskIconType, taskColor) => {
  let statusIconUrl;
  let taskIconHtml;

  const color =
    taskColor ||
    (taskStatus === "Assigned"
      ? "#F3C623"
      : taskStatus === "Active"
      ? "blue"
      : taskStatus === "On_Hold"
      ? "red"
      : taskStatus === "In_Progress"
      ? "#EE66A6"
      : taskStatus === "Completed"
      ? "green"
      : "#A0A0A0"); // Default color if no match

  // Render the status icon with the resolved color
  statusIconUrl = ReactDOMServer.renderToString(
    <FaLocationDot size={36} color={color} />
  );

  switch (taskIconType) {
    case "SUBMISSION":
      taskIconHtml = ReactDOMServer.renderToString(
        <IoCheckmarkDone size={20} color="white" />
      );
      break;
    case "NOT_COMPLETED":
      taskIconHtml = ReactDOMServer.renderToString(
        <RxCross2 size={20} color="white" />
      );
      break;
    default:
      taskIconHtml = ReactDOMServer.renderToString(<span></span>);
  }

  return L.divIcon({
    html: `
      <div style="position: relative;">
        <h1>${statusIconUrl} </h1>
        <div style="position: absolute; top: 0; left: 5px; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;">
          ${taskIconHtml}
        </div>
      </div>
    `,
    className: "custom-combined-icon",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [0, -41],
  });
};

const MarkerClusterComponent = ({ markers, onMarkerClick }) => {
  const map = useMap();
  console.log(markers);
  useEffect(() => {
    const markerClusterGroup = L.markerClusterGroup();

    markers.forEach((task) => {
      const marker = L.marker([task.latitude, task.longitude], {
        icon: getCombinedMarkerIcon(task.taskStatus, task.icon, task.color), // Combined icon
      });
      console.log(task);
      marker.on("click", () => onMarkerClick(task.taskId));
      markerClusterGroup.addLayer(marker);
    });

    map.addLayer(markerClusterGroup);
    return () => {
      map.removeLayer(markerClusterGroup);
    };
  }, [map, markers, onMarkerClick]);

  return null;
};

const removeDrawnRectangle = (drawnItems) => {
  if (drawnItems) {
    drawnItems.clearLayers();
  } else {
    console.error("drawnItems is undefined.");
  }
};

const DrawControl = ({
  markers,
  onFilteredMarkers,
  drawingEnabled,
  onAssignTasks,
  selectedOrgId,
  drawnItems,
  filteredTaskIds,
  setFilteredTaskIds,
}) => {
  const map = useMap();

  useEffect(() => {
    map.addLayer(drawnItems);

    if (drawingEnabled) {
      const drawControl = new L.Control.Draw({
        edit: false,
        draw: {
          rectangle: true,
          polygon: false,
          circle: true,
          circlemarker: false,
          marker: false,
          polyline: {
            shapeOptions: {
              color: "#3388ff",
              weight: 4,
            },
          },
        },
      });

      map.addControl(drawControl);

      map.on(L.Draw.Event.CREATED, async function (event) {
        const layer = event.layer;

        // Handle only Polyline
        if (event.layerType === "polyline") {
          const latLngs = layer.getLatLngs();

          // Close the polyline by connecting the last point to the first point
          if (latLngs.length > 1) {
            latLngs.push(latLngs[0]); // Connect to the first point
            layer.setLatLngs(latLngs); // Update the layer with closed polyline
          }
        }

        drawnItems.addLayer(layer);

        // Perform filtering logic for markers within bounds
        const bounds = layer.getBounds();
        const filteredMarkers = markers.filter((marker) =>
          bounds.contains([marker.latitude, marker.longitude])
        );
        const newTaskIds = filteredMarkers.map((marker) => marker.taskId);
        console.log(newTaskIds, "newTaskIds");
        onFilteredMarkers(newTaskIds);

        try {
          await onAssignTasks(newTaskIds, selectedOrgId);
        } catch (error) {
          console.error("Failed to assign tasks:", error);
        }
      });

      return () => {
        map.removeControl(drawControl);
      };
    }

    return () => {
      if (!drawingEnabled) {
        drawnItems.clearLayers();
      }
    };
  }, [
    map,
    markers,
    onFilteredMarkers,
    drawingEnabled,
    onAssignTasks,
    selectedOrgId,
    drawnItems,
  ]);

  return null;
};

const MapViewTask = () => {
  const [markers, setMarkers] = useState([]);
  const [filteredTaskIds, setFilteredTaskIds] = useState([]);
  const [drawingEnabled, setDrawingEnabled] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [assignTask, setAssignTask] = useState(false);
  const [fieldAgent, setFieldAgent] = useState();
  const [selectedFieldAgent, setSelectedFieldAgent] = useState([]);
  const [showTaskDetails, setShowTaskDetails] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [taskDetail, setTaskDetail] = useState([]);
  const [showFieldAgent, setShowFieldAgent] = useState(false);
  const [drawnItems] = useState(new L.FeatureGroup());
  const [filteredCount, setFilteredCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true); // Add a loading state
  const [searchText, setSearchText] = useState("");

  
  const context = useContext(AppContext);
  const {
    workflowId,
    userType,
    setSelectedOrgId,
    selectedOrgId,
    taskStatusContext,
    setTaskStatusContext,
    langMode,
    theme,
  } = context;
  const position = [21.512, 55.923];
  const [id, setId] = useState();
  const [loading, setLoading] = useState(false);
  const [assigneName, setAssignName] = useState([]);

  // This will go to the mapview toolbar as a prop for using it here part of assignee filter p1
  const [selectedAssigneeId, setSelectedAssigneeId] = useState("");

  // Log whenever `selectedAssigneeId` changes part of assignee filter p1
  useEffect(() => {
    console.log("Selected Assignee ID:", selectedAssigneeId);
  }, [selectedAssigneeId]);

  // We are making changes to the pre-existing function for working on the basis of the selectedAssigneeID also
  const fetchTasks = useCallback(async () => {
    setIsLoading(true);

    // When we get selectedAssigneeId so we will shift our taskStatus to Assigned
    const dynamicTaskStatus = selectedAssigneeId
      ? "Assigned"
      : taskStatusContext;

    // Debug log for API parameters
    console.log("Fetching tasks with:", {
      workflowId,
      taskStatus: dynamicTaskStatus,
      assigneeId: selectedAssigneeId,
    });

    const data = await getTaskAPI(
      workflowId,
      dynamicTaskStatus,
      selectedAssigneeId
    );

    if (data?.body?.value?.taskList) {
      console.log("Tasks received:", data.body.value.taskList); // Debug log for tasks
      setMarkers(data.body.value.taskList);
    } else {
      setMarkers([]);
      console.log("No tasks received");
    }

    setIsLoading(false);
  }, [workflowId, selectedAssigneeId]);

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks, taskStatusContext, selectedAssigneeId]);

  // Here when the office user or admin wants to see something then they cannot see it because it cannot get the organisation id so we will take
  // it from them and then pass it into selectedOrgId

  const getOrganizationId = () => {
    try {
      const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
      console.log(userDetails?.organization?.organizationId);
      return userDetails?.organization?.organizationId || null;
    } catch (error) {
      console.error(
        "Error retrieving organizationId from sessionStorage:",
        error
      );
      return null;
    }
  };

  useEffect(() => {
    let orgId = null;

    if (userType === "super_admin") {
      orgId = selectedOrgId; // Use the selectedOrgId directly for super_admin
    } else {
      orgId = getOrganizationId(); // Get organizationId from sessionStorage
    }

    if (orgId) {
      setSelectedOrgId(orgId); // Set selectedOrgId for API calls
    } else {
      console.warn("No organizationId found. Please check sessionStorage.");
    }
  }, [userType]);

  const handleFieldAgentSelect = (userId) => {
    setSelectedFieldAgent((prevAgents) => {
      let updatedAgents;
      if (prevAgents.includes(userId)) {
        updatedAgents = prevAgents.filter((agentId) => agentId !== userId); // Deselect
      } else {
        updatedAgents = [...prevAgents, userId]; // Select
      }
      console.log("Updated Field Agents: ", updatedAgents); // Log updated list
      return updatedAgents;
    });
  };

  const handleAssignTask = async () => {
    try {
      const response = await fetchFieldAgentMap(selectedOrgId);
      setFieldAgent(response.body.value);
      setAssignTask(true);
    } catch (error) {
      console.error("Failed to fetch field agent map:", error);
    }
  };

  const handleMarkerClick = (taskId) => {
    getTaskDetails(taskId)
      .then((data) => {
        if (data && data.body && data.body.value) {
          const taskDetail = data.body.value.task; // Get task details from the response
          const assignees = data.body.value.assignees; // Get assignees from the response
          if (taskDetail.taskDetails) {
            taskDetail.taskDetailsParsed = JSON.parse(taskDetail.taskDetails);
          }
          setTaskDetail(taskDetail);
          setId(taskId);
          setAssignName(assignees); // No need to assign to `Name`, just set directly
          setSelectedTask(taskId);
          setShowTaskDetails(true);
          setFilteredTaskIds([taskId]);
          setAssignTask(true);
          handleAssignTask();
        } else {
          console.error("Unexpected data structure:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleTaskAssign = async () => {
    setLoading(true);
    // if(filteredTaskIds.length > 100){
    //   toast.warn("You can't assign more than 100 task once !");
    //   setLoading(false);

    //   return
    // }
    try {
      const data = await multiassignTasks(
        filteredTaskIds,
        selectedFieldAgent,
        workflowId
      );
      if (data.header.code === 600) {
        setMarkers((prevMarkers) =>
          prevMarkers.filter((task) => !filteredTaskIds.includes(task.taskId))
        );
        setAssignTask(false);
        toast.success(data.body.value);
        setFilteredTaskIds([]);
        removeDrawnRectangle(drawnItems);
        setDrawingEnabled(false);
        setSelectedFieldAgent([]);
        setLoading(false);
      } else {
        toast.error(data.body.value);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error assigning tasks:", error);
      setLoading(false);
    }
  };
  const TaskAssign = async () => {
    setLoading(true);
    // if(filteredTaskIds.length > 100){
    //   toast.warn("You can't assign more than 100 task once !");
    //   setLoading(false);
    //   return
    // }
    try {
      const data = await assignTasks(
        filteredTaskIds,
        selectedFieldAgent,
        workflowId
      );
      if (data.header.code === 600) {
        setMarkers((prevMarkers) =>
          prevMarkers.filter((task) => !filteredTaskIds.includes(task.taskId))
        );
        setAssignTask(false);
        toast.success(data.body.value);
        setFilteredTaskIds([]);
        removeDrawnRectangle(drawnItems);
        setDrawingEnabled(false);
        setSelectedFieldAgent([]);
        setLoading(false);
      } else {
        toast.error(data.body.value);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error assigning tasks:", error);
      setLoading(false);
    }
  };

  const handleUnassign = async () => {
    try {
      // Ensure filteredTaskIds is a valid array
      if (!Array.isArray(filteredTaskIds) || filteredTaskIds.length === 0) {
        throw new Error("No task IDs provided for unassigning.");
      }

      // Construct payload
      const payload = {
        workflowId,
        taskIds: filteredTaskIds, // Always include valid task IDs
        ...(selectedAssigneeId && { assigneeId: selectedAssigneeId }), // Include assigneeId if defined
      };

      console.log("Payload being sent:", payload); // Debug log

      const data = await unassignTask(payload); // Call API

      if (data.header.code === 600) {
        // Successfully unassigned tasks
        setMarkers((prevMarkers) =>
          prevMarkers.filter((task) => !filteredTaskIds.includes(task.taskId))
        );
        setAssignTask(false);
        toast.success("Task unassigned Successfully");
        setFilteredTaskIds([]); // Clear filtered tasks
        removeDrawnRectangle(drawnItems); // Clear map drawings
        setDrawingEnabled(false);
        setSelectedFieldAgent([]); // Clear field agent selection
      } else {
        // Handle API errors
        toast.error(data.body?.value || "Failed to unassign task.");
      }
    } catch (error) {
      console.error("Failed to unassign task:", error.message);
      toast.error("An error occurred while unassigning the task.");
    } finally {
      setLoading(false); // Ensure loading state is reset
    }
  };

  const handleFilteredMarkers = (taskIds) => {
    if (taskIds && taskIds.length > 0) {
      setFilteredTaskIds(taskIds);
      setFilteredCount(taskIds.length);
      console.log("Filtered Task IDs:", taskIds);
    }
  };

  const fieldListClose = () => {
    setAssignTask(false);
    removeDrawnRectangle(drawnItems);
    setShowTaskDetails(false);
    setDrawingEnabled(false);
  };
  const rightSidebarStyle = {
    top: "0",
    position: "absolute",
    right: "240px",
    overflowY: "auto",
    zIndex: "9999",
  };

  useEffect(() => {
    console.log(markers);
  }, [markers]);

  return (
    <div className="relative w-full overflow-hidden">
      {loading && <Spinner />}
      <h4
        className={`m-3 text-sm font-semibold uppercase text-gray-800 ${
          theme === "dark"
            ? "dark-mode  "
            : theme === "high-contrast"
            ? "high-contrast  "
            : ""
        }`}
      >
        {translations[langMode].mapview}
      </h4>
      <MapViewToolbar
        showFieldAgent={showFieldAgent}
        setShowFieldAgent={setShowFieldAgent}
        onMultiSelect={() => {
          if (!workflowId) {
            toast.error("Select workflow first");
            return;
          }
          setDrawingEnabled((prev) => !prev);
          setFilteredCount(0);
        }}
        drawingEnabled={drawingEnabled}
        setDrawingEnabled={setDrawingEnabled}
        // Sending selectedorgid prop directly from here to the component and pass new state also #Changes
        selectedOrgId={selectedOrgId}
        setSelectedAssignee={setSelectedAssigneeId}
      />
      <div
        className="relative border rounded-md my-2 "
        style={{ zIndex: "10" }}
      >
        <MapContainer
          center={position}
          zoom={5}
          className="relative"
          style={{ height: "80vh", width: "98%", overflow: "hidden" }}
          id="map"
        >
          {/* <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          /> */}

          <LayersControl position="topright">
            {/* Default OpenStreetMap View */}
            <LayersControl.BaseLayer checked name="Default View">
              <TileLayer
                // url="https://api.maptiler.com/maps/topo-v2/256/{z}/{x}/{y}.png?key=hsgrpMGlBpqRtYR804n6"
                url="https://tile.openstreetmap.de/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors | &copy; <a href="https://www.maptiler.com/">MapTiler</a>'
              />
            </LayersControl.BaseLayer>

            {/* Enhanced Satellite View */}
            <LayersControl.BaseLayer name="Satellite View">
              {/* <TileLayer
                url="https://api.maptiler.com/maps/satellite/256/{z}/{x}/{y}.jpg?key=hsgrpMGlBpqRtYR804n6"
                // url="https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                // attribution='Tiles © <a href="https://www.esri.com/">Esri</a>, Maxar, Earthstar Geographics, and the GIS User Community'
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors | &copy; <a href="https://www.maptiler.com/">MapTiler</a>'
              /> */}

              <TileLayer
                url="https://mt.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
                attribution='&copy; <a href="https://www.google.com/maps">Google Maps</a>'
              />
            </LayersControl.BaseLayer>

            {/* Optional: Add Labels to Satellite View */}
            {/* <LayersControl.Overlay name="Labels on Satellite View">
              <TileLayer
                url="https://services.arcgisonline.com/arcgis/rest/services/Reference/MapServer/tile/{z}/{y}/{x}"
                attribution='Labels © <a href="https://www.esri.com/">Esri</a>, HERE, Garmin, and other contributors'
              />
            </LayersControl.Overlay> */}
          </LayersControl>
          {isLoading && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: "9999",
              }}
            >
              <Spinner />
              <p>Loading tasks...</p>
            </div>
          )}
          <MarkerClusterComponent
            markers={markers}
            onMarkerClick={handleMarkerClick}
          />
          {drawingEnabled &&
            filteredCount !== null &&
            filteredCount !== undefined && (
              <div
                style={{
                  position: "absolute",
                  top: `50px`,
                  left: `250px`,
                  transform: "translate(165%, 0%)", // Adjusted this line
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "white",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  zIndex: "9999",
                }}
              >
                {filteredCount} Tasks
              </div>
            )}

          <DrawControl
            markers={markers}
            onFilteredMarkers={handleFilteredMarkers}
            drawingEnabled={drawingEnabled}
            onAssignTasks={handleAssignTask}
            selectedOrgId={selectedOrgId}
            drawnItems={drawnItems} // Pass drawnItems to DrawControl
            setFilteredTaskIds={setFilteredTaskIds}
            filteredTaskIds={filteredTaskIds}
          />
          <ResetZoom workflowId={workflowId} taskStatus="Active" />
        </MapContainer>
        {showFieldAgent && (
          <div
            className="mx-3  right-0 absolute"
            style={{
              width: "220px",
              top: "0",
              overflowY: "auto",
              zIndex: "9999",
            }}
          >
            <FieldAgentList />
          </div>
        )}
        {assignTask && (
          <div
            style={rightSidebarStyle}
            className={`absolute w-80  bg-gray-50 p-3 rounded-lg shadow-lg z-50    ${
              theme === "dark"
                ? "dark-mode "
                : theme === "high-contrast"
                ? "high-contrast  "
                : ""
            } `}
          >
            <div className="flex items-center gap-1">
              <h6
                className={`  ${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast  "
                    : ""
                } text-xs font-semibold text-wrap`}
              >
                Field Staff
              </h6>
              <button
                className="px-2 py-1 text-xs font-medium bg-blue-800 text-white rounded-md"
                onClick={TaskAssign}
              >
                Assign
              </button>
              <button
                onClick={handleTaskAssign}
                className="px-2 py-1 text-xs text-wrap font-medium bg-blue-800 text-white rounded-md"
              >
                Re-Assign
              </button>
              <button
                onClick={handleUnassign}
                className="px-2 py-1 text-xs text-wrap font-medium bg-blue-800 text-white rounded-md"
              >
                Un-Assign
              </button>
              <button
                className="absolute right-2 text-gray-600 hover:text-gray-800"
                onClick={fieldListClose}
              >
                <MdClose />
              </button>
            </div>
            <div className="overflow-y-auto h-auto max-h-[162px] border border-gray-300 rounded-md mt-2 px-2 overflow-x-auto  ">
              {fieldAgent &&
                fieldAgent.map((agent) => (
                  <div
                    key={agent.userId}
                    className={`bg-white border rounded-md border-gray-300  py-2 my-1 px-2 ${
                      theme === "dark"
                        ? "dark-mode "
                        : theme === "high-contrast"
                        ? "high-contrast  "
                        : ""
                    }`}
                  >
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        name="fieldAgent"
                        onClick={() => handleFieldAgentSelect(agent.userId)}
                        checked={selectedFieldAgent.includes(agent.userId)}
                        className={`w-3 h-3 text-blue-600 bg-gray-100 border-gray-300  ${
                          theme === "dark"
                            ? "dark-mode "
                            : theme === "high-contrast"
                            ? "high-contrast  "
                            : ""
                        }`}
                      />
                      <label
                        className={`ms-2 text-xs font-medium text-gray-900  ${
                          theme === "dark"
                            ? "dark-mode "
                            : theme === "high-contrast"
                            ? "high-contrast  "
                            : ""
                        }`}
                      >
                        {agent.name}
                      </label>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}

        <StatusFilter
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          setShowTaskDetails={setShowTaskDetails}
          setMarkers={setMarkers}
          setAssignTask={setAssignTask}
          selectedAssigneeId={selectedAssigneeId}
          setSelectedAssigneeId={setSelectedAssigneeId}
        />

        {showTaskDetails && (
          <MarkerInfoModal
            taskDetail={taskDetail}
            onClose={() => setShowTaskDetails(false)}
            setAssignTask={setAssignTask}
            id={id}
            setId={setId}
            assigneName={assigneName}
            setAssignName={setAssignName}
          />
        )}
      </div>
    </div>
  );
};

export default MapViewTask;
