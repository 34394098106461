import React, { useContext, useEffect, useState, useRef } from "react";
import { FaPerson, FaUserGroup } from "react-icons/fa6";
import { FiMenu } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import AppContext from "../../context/AppContext";
import { toast } from "react-toastify";
import MapView from "../../assets/map-sharp.png";
import MultiSelect from "../../assets/multi-select.png";
import createImg from "../../assets/Vector (6).png";
import importImg from "../../assets/arrow-undo-circle.png";
import assignImg1 from "../../assets/Group 2085662708.png";
import assignImg2 from "../../assets/Group 2085662707.png";
import {
  assignByArea,
  smartAssignRulesList,
} from "../../service/Configuration";
import { FaExpand } from "react-icons/fa";
import debounce from "lodash/debounce";
import { fetchFieldAgentMap, getFieldAgents, smartAssign } from "../../service/Task";
import { RxCross2 } from "react-icons/rx";
import Cookies from "js-cookie";
import Joyride from "react-joyride";
import { Spinner } from "../../Spinner";
import translations from "../common/Translations";
import FilterSelect from "../common/FilterSelect";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const MapViewToolbar = ({
  selectedtask,
  setIsChecked,
  selectedOrgId,
  setSelectedAssignee,
  showFieldAgent,
  setShowFieldAgent,
  onMultiSelect,
  drawingEnabled,
}) => {
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const {
    maptoolbar,
    runTour,
    setIsDraggable,
    isDraggable,
    setFieldAgentList,
    workflowId,
    taskStatusContext,
    langMode,
    setActive,
    setPriority
  } = context;
  const [showAssignByRules, setShowAssignByRules] = useState(false);
  const { theme } = useContext(AppContext);
  const [assignmentList, setAssignmentList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(4);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);
  const [showSmartAssignModal, setShowSmartAssignModal] = useState(false);
  const [assignRuleloading, setassignRuleloading] = useState(false);
  const initialLoadRef = useRef(null);
  const [isreassignChecked, setIsReassignChecked] = useState(false);

  // This is added to add the assignee filter p1 #Changes
  const [updateFieldAgentList, setUpdateFieldAgentList] = useState([]);
  const [selectedAssigneeName, setSelectedAssigneeName] = useState("Default");

  // Fetch Field Agents when `selectedOrgId` changes part of the new assignee filter
  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const agents = await fetchFieldAgentMap(selectedOrgId); // Use API
        setUpdateFieldAgentList(agents.body.value);
      } catch (error) {
        console.error("Failed to fetch field agents:", error);
      }
    };

    if (selectedOrgId) fetchAgents();
  }, [selectedOrgId]);

  // Part of the new assignee filter
  const handleAssigneeChange = (selectedName) => {
    if (selectedName === "Default") {
      setSelectedAssignee(""); // Reset the selected ID in the parent
      setSelectedAssigneeName("Default"); // Reset the dropdown text
    } else {
      const selectedAgent = updateFieldAgentList.find(
        (agent) => agent.name === selectedName
      ); // Find agent by name
      if (selectedAgent) {
        setSelectedAssignee(selectedAgent.userId); // Pass `userId` to parent
        setSelectedAssigneeName(selectedName); // Update the dropdown text
      }
    }
  };

  // Toggle the checkbox state when clicked
  const handleToggle = () => {
    setIsReassignChecked(!isreassignChecked);
  };
  const toggleDraggable = () => {
    setIsDraggable(!isDraggable);
  };

  useEffect(() => { }, [isDraggable]);
  useEffect(() => {
    const fetchFieldAgents = async () => {
      try {
        const agents = await getFieldAgents();
        setFieldAgentList(agents);
      } catch (error) { }
    };
    fetchFieldAgents();
  }, []);

  const toggleSmartAssignModal = async () => {
    setIsLoading(true); // Set loading state to true
    try {
      const responseData = await smartAssign(workflowId, isreassignChecked);
      if (responseData.header.code === 600) {
        toast.success(responseData.body.value);
        setIsReassignChecked(false)
      } else {
        toast.error(responseData.body.value);
      }
    } catch (error) {
      console.error("Error during smart assign:", error);
      toast.error("An error occurred while assigning tasks.");
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };
  const handleScroll = debounce(() => {
    if (isLoading || !hasMore) return;

    const { scrollTop, scrollHeight, clientHeight } = initialLoadRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 200) {
      setPage((prevPage) => prevPage + 1);
    }
  }, 200);

  useEffect(() => {
    const currentDiv = initialLoadRef.current;
    if (currentDiv) {
      currentDiv.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (currentDiv) {
        currentDiv.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    if (!isLoading && hasMore) {
      fetchAssignmentRules();
    }
  }, [page]);

  const fetchAssignmentRules = async (reset = false) => {
    setIsLoading(true);
    try {
      await smartAssignRulesList(
        reset,
        workflowId,
        page,
        size,
        setIsLoading,
        setAssignmentList,
        setHasMore,
        setError
      );
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching assignment rules:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAssignByArea = async (selectedIds) => {
    try {
      setassignRuleloading(true);
      const response = await assignByArea(selectedIds, isreassignChecked, workflowId);
      if (response.header.code === 600) {
        toast.success(response.body.value);
        setassignRuleloading(false);
        setShowSmartAssignModal(false);
        setIsReassignChecked(false)
      } else {
        throw new Error(response.header.message);
      }
    } catch (error) {
      console.error("Error assigning tasks by area:", error);
      toast.error("Failed to assign tasks by area.");
      setassignRuleloading(false);
    }
  };

  const openSmartAssignPopup = () => {
    if (workflowId) {
      setShowSmartAssignModal(true);
    } else {
      toast.warn("Please select activity");
    }
  };

  const handleMapView = () => {
    navigate("/maptask");
  };
  const handleListView = () => {
    navigate("/task-manage");
    setActive('Status')
    setPriority('Priority')
  };
  const getRulesList = () => {
    setShowAssignByRules(!showAssignByRules);
    if (!showAssignByRules) {
      setPage(0);
      setSize(4);
      setAssignmentList([]);
      fetchAssignmentRules(true);
    }
  };

  const handleFieldAgentClick = () => {
    setShowFieldAgent((prevState) => !prevState);
  };
  const handleGoCsv = () => {
    Cookies.set("viewMode", "map");
    navigate("/task/import-csv");
  };
  const handleGoCreateTask = () => {
    Cookies.set("viewmode", "map");
    navigate("/task/create-task");
  };
  const handleClose = () => {
    setShowSmartAssignModal(false);
    setShowAssignByRules(false);
    setPage(0);
    setSize(4);
    setAssignmentList([]);
  };
  return (
    <>
      <Joyride
        steps={maptoolbar}
        run={runTour}
        continuous
        showSkipButton
        showProgress
        scrollToFirstStep
        styles={{
          options: {
            arrowColor: "#fff",
            zIndex: 10000,
            overlayColor: "rgba(0, 0, 0, 0.5)",
            primaryColor: "#4C3BCF",
            textColor: "#333",
          },
          tooltip: {
            borderRadius: "10px",
            transition: "transform 0.3s ease, opacity 0.3s ease",
          },
          buttonSkip: {
            color: "#4C3BCF",
          },
          buttonNext: {
            backgroundColor: "#4C3BCF",
            transition: "background-color 0.3s ease",
          },
          buttonBack: {
            color: "#4C3BCF",
          },
          spotlight: {
            transition: "box-shadow 0.5s ease",
          },
        }}
      />
      <div
        className={`flex justify-between items-center bg-white z-50 rounded-md ${theme === "dark"
          ? "dark-mode border p-1"
          : theme === "high-contrast"
            ? "high-contrast"
            : ""
          }`}
      >
        <div className="flex items-center gap-4 z-50">
          <div className=" flex rounded-md">
            <button
              onClick={handleMapView}
              className={`py-2 px-2 bg-blue-800   text-md rounded-l-md font-semibold border border-gray-300 transition-all duration-300 ${theme === "dark"
                ? " bg-blue-800  "
                : theme === "high-contrast"
                  ? "bg-black "
                  : ""
                }`}
            >
              <img
                src={MapView}
                alt="mapview"
                className="filter brightness-0 invert "
              />
            </button>
            <button
              onClick={handleListView}
              className={`py-2 px-2 rounded-r-md  text-md  font-semibold border border-gray-300 transition-all duration-300 ${theme === "dark"
                ? "dark-mode  "
                : theme === "high-contrast"
                  ? "high-contrast  "
                  : ""
                }`}
            >
              <FiMenu />
            </button>
          </div>
          <div className="flex items-center gap-2">
            <button
              onClick={onMultiSelect}
              type="button"
              className={`multiselect ${!drawingEnabled
                ? "text-gray-900  text-xs bg-white hover:bg-blue-800 hover:text-white border-2  focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg px-4 py-2.5 text-center inline-flex gap-2 items-center  me-2"
                : `text-white bg-blue-800 hover:bg-indigo-600 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex gap-2 items-center me-2 
                 ${theme === "dark"
                  ? "dark-mode"
                  : theme === "high-contrast"
                    ? ""
                    : ""
                }`
                } `}
            >
              {isDraggable ? (
                <FaExpand />
              ) : (
                <img src={MultiSelect} alt="multi-select" />
              )}
              {translations[langMode].multiSelect}
            </button>

            <button
              onClick={openSmartAssignPopup}
              type="button"
              className={`smartAssign text-white  bg-yellow-400 hover:bg-yellow-500  focus:ring-4 
              focus:outline-none  font-medium rounded-lg  text-xs px-2 py-2.5 text-center 
              inline-flex gap-2 items-center dark:focus:ring-gray-600  dark:border-gray-700
               dark:text-white dark:hover:bg-gray-700 me-2  ${theme === "dark" ? " " : theme === "high-contrast" ? " " : ""
                }`}
            >
              <FaUserGroup size={16} /> {translations[langMode].smartAssign}
            </button>

            <button
              onClick={handleFieldAgentClick}
              type="button"
              className={`fieldagent focus:outline-none
            focus:ring-gray-100 font-medium rounded-lg  text-xs px-2 py-2.5 text-center 
            inline-flex gap-2 items-center dark:focus:ring-gray-600 dark:border-gray-700
            dark:text-white dark:hover:bg-gray-700 me-2 ${showFieldAgent
                  ? "bg-green-600 text-white"
                  : "bg-green-500 border hover:bg-green-600  text-white"
                } ${theme === "dark" ? "" : theme === "high-contrast" ? "" : ""}`}
            >
              <FaPerson size={`${showFieldAgent ? 20 : 16}`} />
              {translations[langMode].fieldAgent}
            </button>

            {taskStatusContext !== "Active" ? (
              <FilterSelect
                defaultText="Select Assignee"
                options={
                  updateFieldAgentList.length > 0
                    ? [{ id: "", value: "Default" }, ...updateFieldAgentList.map(agent => ({ id: agent.userId, value: agent.name }))]
                    : [{ id: "", value: "Default" }]
                }
                value={selectedAssigneeName}
                onChange={(selectedName) => {
                  const selectedAgent = updateFieldAgentList.find(agent => agent.name === selectedName);
                  setSelectedAssignee(selectedAgent ? selectedAgent.userId : "");
                  setSelectedAssigneeName(selectedName);
                }}
              />
            ) : (
              <p className="text-sm text-gray-500">Filter options are not available for Active tasks.</p>
            )}

          </div>
        </div>
        <div>
          <button
            type="button"
            className="createTask px-2 py-2 text-xs mr-4 font-medium text-center inline-flex items-center text-white bg-gray-700 rounded-lg hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
            onClick={handleGoCreateTask}
          >
            <img src={createImg} className="px-1" alt="create tmg" />
            {translations[langMode].createNewTask}
          </button>
          <button
            type="button"
            className="gocsvs px-2 py-2 text-xs mr-4 font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-lg hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-gray-300 "
            onClick={handleGoCsv}
          >
            <img src={importImg} className="px-1 h-5" alt="import cvs" />
            {translations[langMode].importCSV}
          </button>
        </div>
      </div>
      {showSmartAssignModal && (
        <>
          <div
            className={`bg-gray-400 shadow fixed inset-0 z-50 flex items-center justify-center bg-opacity-50
                  ${theme === "dark"
                ? "dark-mode"
                : theme === "high-contrast"
                  ? "high-contrast"
                  : ""
              }`}
            style={{ zIndex: "9999" }}
          >
            <div className="bg-white w-[500px] rounded-lg">
              <div className="flex items-center justify-between p-2 md:p-5  rounded-t dark:border-gray-600">
                <div className="flex flex-col">
                  <h3 className="text-sm font-bold text-gray-900 dark:text-white ">
                    Smart Assign
                  </h3>

                  <div className="flex flex-row justify-between toggle mt-2">
                    <label
                      htmlFor="dark-toggle"
                      className="flex items-center cursor-pointer gap-3"
                    >
                      <div className="dark:text-white text-gray-900 font-normal">
                        Re-assign
                      </div>
                      <div className="relative">
                        <input
                          type="checkbox"
                          name="dark-mode"
                          id="dark-toggle"
                          className="checkbox hidden"
                          checked={isreassignChecked}
                          onChange={handleToggle}
                        />

                        <div
                          className={`block border-[1px] dark:border-white  w-12 h-6 rounded-full ${isreassignChecked ? "bg-blue-500" : "bg-gray-300"
                            }`}
                        ></div>
                        {/* Inner dot */}
                        <div
                          className={`dot absolute left-1 top-1 dark:bg-white bg-gray-800 w-4 h-4 rounded-full transition-transform duration-300 ${isreassignChecked ? "transform translate-x-6 bg-white" : ""
                            }`}
                        ></div>
                      </div>
                    </label>
                  </div>
                </div>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="small-modal"
                  onClick={handleClose}
                >
                  <RxCross2 />
                </button>
              </div>
              <div className="p-4 grid grid-cols-1 gap-x-2 gap-y-5 sm:grid-cols-4">
                <div className="flex justify-between w-full gap-24 ml-16 items-center">
                  <div
                    className="flex flex-col items-center shadow-md p-5 border border-gray-300 rounded-md"
                    onClick={toggleSmartAssignModal}
                  >
                    {isLoading ? (
                      <Spinner />
                    ) : (
                      <>
                        <img src={assignImg1} alt="Smart Assign" />
                        <span className="text-sm whitespace-nowrap">
                          Smart Assign
                        </span>
                      </>
                    )}
                  </div>
                  <div
                    className="flex flex-col items-center  shadow-md p-5 border border-gray-300 rounded-md"
                    onClick={getRulesList}
                  >
                    <img src={assignImg2} alt="Assign by Rules" />
                    <span
                      className="text-sm whitespace-nowrap"
                      style={{ cursor: "pointer" }}
                    >
                      Assign by Rules
                    </span>
                  </div>
                </div>
                {showAssignByRules && (
                  <>
                    <div
                      className={`transition-all duration-300 sm:col-span-4 overflow-y-auto  ${showAssignByRules ? "max-h-40" : "max-h-0"
                        }`}
                      ref={initialLoadRef}
                    >
                      {" "}
                      <ul className="pl-5 list-none">
                        {assignmentList.map((assignment, index) => (
                          <li
                            className="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600"
                            key={index}
                          >
                            <div className="flex items-center ps-3">
                              <input
                                id={`rule-${index}`}
                                type="checkbox"
                                value={assignment.id}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-500 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                onChange={(e) => {
                                  const updatedList = assignmentList.map(
                                    (rule, idx) =>
                                      idx === index
                                        ? {
                                          ...rule,
                                          isSelected: e.target.checked,
                                        }
                                        : rule
                                  );
                                  setAssignmentList(updatedList);
                                }}
                              />
                              <label
                                htmlFor={`rule-${index}`}
                                className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                              >
                                {assignment.ruleName}
                              </label>
                            </div>
                          </li>
                        ))}
                        {isLoading && <p>Loading...</p>}
                        {!hasMore && !isLoading && <p>No more rules to load</p>}
                      </ul>
                    </div>
                    <div className="flex items-center float-right p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                      <button
                        onClick={handleClose}
                        data-modal-hide="small-modal"
                        type="button"
                        className="py-1.5 px-3 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                      >
                        Cancel
                      </button>
                      <button
                        data-modal-hide="small-modal"
                        type="button"
                        onClick={() =>
                          handleAssignByArea(
                            assignmentList
                              .filter((rule) => rule.isSelected)
                              .map((rule) => rule.id)
                          )
                        }
                        className="text-white bg-blue-800 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 ml-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        Assigne
                      </button>
                      {assignRuleloading && <Spinner />}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default MapViewToolbar;
