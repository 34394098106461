import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { FiMenu } from "react-icons/fi";
import { IoDownloadOutline, IoSearch } from "react-icons/io5";
import { LuMoreVertical } from "react-icons/lu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AppContext from "../../context/AppContext";
import FilterSelect from "../common/FilterSelect";
import FieldListModal from "./FieldStaffListModal";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import MapView from "../../assets/map-sharp.png";
import createImg from "../../assets/Vector (6).png";
import importImg from "../../assets/arrow-undo-circle.png";
import DeletePermission from "../suspence/DeletePermission";
import { fetchFilterExcel } from "../../service/Task";
import {
  deleteTasks,
  putTasksOnHold,
  fetchFieldAgentMap,
  deleteAllRecords,
  unassignTask,
} from "../../service/Task";
import { SubActivity } from "../common/task/SubActivity";
import { FaCirclePause, FaRepeat, FaUser } from "react-icons/fa6";
import { MdDelete, MdOutlineDeleteOutline } from "react-icons/md";
import { fetchTasks } from "../../service/Task";
import Modal from "../DownloadModal/Modal";
import Joyride from "react-joyride";
import translations from "../common/Translations";
import ReAssignModal from "./ReAssignModal";
import { TbUserCancel } from "react-icons/tb";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const TaskListToolbar = ({
  openTab,
  setOpenTab,
  isChecked,
  selectedtask,
  setIsChecked,
}) => {
  const [open, setOpen] = useState(false);
  const [openReassign, setOpenReassign] = useState(false);
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const {
    setPriority,
    setActive,
    selectAll,
    setAreaCode,
    areaCode,
    accountNo,
    setAccountNo,
    setFieldAgentList,
    fieldAgentList,
    setSelectedUserId,
    workflowId,
    priority,
    active,
    taskData,
    setTaskData,
    selectedOrgId,
    setSelectedOrgId,
    setSelectedOrgName,
    langMode,
    tasktollbar,
    runTour,
    key,
    userType,
  } = context;
  const [selectedUserName, setSelectedUserName] = useState(0);
  const [selectedStatusName, setselectedStatusName] = useState("Status");
  const [selectedPriorityName, setselectedPriorityName] = useState("Priority");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState("");
  const [deleteItemType, setDeleteItemType] = useState("");
  const [downloadModal, setDownloadModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const location = useLocation(); // Get the current route
  const [unassign, setUnassign] = useState(false);
  const [assigneeId, setAssigneeId] = useState();
  const [inputValue, setInputValue] = useState(""); // Temporary state for input

  useEffect(() => {
    const savedSearch = sessionStorage.getItem("taskSearchValue");
    if (savedSearch) {
      setAccountNo(savedSearch);
    }
  }, []);
  const { theme } = useContext(AppContext);
  const priorityoptions = [
    { id: "Everything", value: "Default" },
    { id: "Low_Priority", value: "Low_Priority" },
    { id: "Medium_Priority", value: "Medium_Priority" },
    { id: "High_Priority", value: "High_Priority" },
  ];
  const activeoptions = [
    { id: "Everything", value: "Default" },
    { id: "Active", value: "Active" },
    { id: "Assigned", value: "Assigned" },
    { id: "In_Progress", value: "In_Progress" },
    { id: "Completed", value: "Completed" },
    { id: "On_Hold", value: "On_Hold" },
  ];
  const handleShowModal = () => {
    if (!workflowId) {
      toast.warn("Select Workflow First !");
      return;
    }
    setDownloadModal(true);
  };
  const showDeleteModal = (type, id) => {
    if (!workflowId) {
      toast.warn("Select Workflow First !");
      return;
    }
    setDeleteItemType(type);
    setDeleteItemId(id);
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };
  const resetFilters = () => {
    setPriority("Default");
    setActive("Default");
    setSelectedUserId("");
    setSelectedUserName("Default");
    setselectedStatusName("Status");
    setselectedPriorityName("Priority");
    setSelectedOrgName("");
    setSelectedOrgId("");
  };

  const handlePrioritySelection = (selectedValue) => {
    const selectedOption = priorityoptions.find(
      (option) => option.value === selectedValue
    );
    if (selectedOption) {
      setselectedPriorityName(selectedOption.value);
      setPriority(
        selectedOption.id === "Priority" ? "Default" : selectedOption.id
      );
    }
  };

  const handleStatusSelection = (selectedValue) => {
    const selectedOption = activeoptions.find(
      (option) => option.value === selectedValue
    );
    if (selectedOption) {
      setselectedStatusName(selectedOption.value);
      setActive(selectedOption.id === "Status" ? "Default" : selectedOption.id);
    }
  };

  const handleUnassign = async () => {
    try {
      const payload = {
        workflowId,
        taskIds: selectedtask, // Should be an array of task IDs
        ...(assigneeId && { assigneeId }), // Include assigneeId only if it's defined
      };

      console.log("Payload being sent:", payload, selectedtask, assigneeId); // Debug log
      const data = await unassignTask(payload); // Pass the payload

      if (data.header.code === 600) {
        toast.success("Task unassigned successfully!");
        setIsChecked(false);
        handleCloseDeleteModal();

        // const updatedTaskData = taskData.map((task) => {
        //   if (selectedtask.includes(task.taskId)) {
        //     return {
        //       ...task,
        //       taskStatus: "Active", // Update task status
        //       isChecked: false, // Uncheck the task
        //     };
        //   }
        //   return task; // No change for other tasks
        // });

        // setTaskData(updatedTaskData);
        fetchTasks({
          workflowId,
          currentPage: 1,
          pageSize: 10,
          priority: "Priority",
          active: "Status",
        }).then((fetchedTasks) => {
          console.log("Fetched tasks:", fetchedTasks);
          // Update taskData with the new fetched tasks
          setTaskData(fetchedTasks.taskList);
          setActive("Active");
          setPriority("Priority");
          setselectedStatusName("Status");
          setselectedPriorityName("Priority");
        });
      } else {
        toast.error(
          data.body?.error || "An error occurred while unassigning the task."
        );
      }
    } catch (error) {
      console.error("Failed to unassign task:", error);
      toast.error("An error occurred while unassigning the task.");
    }
  };

  const taskDeleteList = async () => {
    try {
      const data = await deleteTasks(selectedtask);
      setIsChecked(false);
      setOpen(false);
      if (selectAll) {
        handleCloseDeleteModal();
        const updatedTaskData = taskData.filter(
          (task) => !selectedtask.includes(task.taskId)
        );
        setTaskData(updatedTaskData);
      } else {
        toast.success("Task deleted successfully");
        const updatedTaskData = taskData.filter(
          (task) => !selectedtask.includes(task.taskId)
        );
        setTaskData(updatedTaskData);
        handleCloseDeleteModal();
      }
    } catch (error) {}
  };

  const handleAreaCodeChange = (event) => {
    setAreaCode(event.target.value);
  };
  // const handleAccountNoChange = (event) => {
  //   const value = event.target.value;
  //   setAccountNo(value);
  //   sessionStorage.setItem("taskSearchValue", value); // Save value in sessionStorage
  // };

  const handleInputChange = (event) => {
    setInputValue(event.target.value); // Update temporary state
  };

  const handleSearch = () => {
    setAccountNo(inputValue); // Set the final value on search
    sessionStorage.setItem("taskSearchValue", inputValue); // Save value in sessionStorage
  };

  useEffect(() => {
    if (inputValue.length === 0) {
      handleSearch();
    }
  }, [inputValue]);

  useEffect(() => {
    const fetchFieldAgents = async () => {
      try {
        const agents = await fetchFieldAgentMap(selectedOrgId);
        setFieldAgentList(agents.body.value);
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };
    fetchFieldAgents();
  }, [selectedOrgId]);

  useEffect(() => {}, [fieldAgentList]);

  const handleAssigneeChange = (selectedId) => {
    setSelectedUserId(selectedId);
    const selectedAgent = fieldAgentList.find(
      (agent) => agent.userId === selectedId
    );
    if (selectedAgent) {
      setSelectedUserName(selectedAgent.name);
      setAssigneeId(selectedAgent.userId);
    } else if (selectedId === "") {
      setSelectedUserName("Default");
    }
  };

  const handleDeleteAllRecord = async (workflowId) => {
    try {
      const responseData = await deleteAllRecords(workflowId);
      if (responseData.header.code === 600) {
        const updatedTaskData = taskData.includes((task) => task.workflowId);
        setTaskData(updatedTaskData);
        toast.success("All records are deleted");
        handleCloseDeleteModal();
      } else {
        throw new Error(responseData.header.message);
      }
    } catch (error) {
      console.error("Error:", error.message);
      toast.error("Error: " + error.message);
    }
  };

  const handleTabChange = (tab) => {
    setOpenTab(tab);
    localStorage.setItem("activeTab", tab);
  };

  const downloadFiltration = async (selectedOption) => {
    setIsLoading(true);
    try {
      const userTypeCookie = Cookies.get("userType");
      const params = {
        workflowIds: workflowId || "",
        priority: priority || "",
        active: active || "",
        areaCode: areaCode || "",
        accountNo: accountNo || "",
        organizationId: "",
      };

      if (selectedOption === "all" && userTypeCookie === "super_admin") {
        params.organizationId = selectedOrgId || "";
        params.workflowIds = "";
      } else if (
        selectedOption === "particular" ||
        userTypeCookie !== "super_admin"
      ) {
        params.organizationId = "";
      }
      const data = await fetchFilterExcel({
        workflowIds: params.workflowIds,
        priority: params.priority,
        active: params.active,
        areaCode: params.areaCode,
        accountNo: params.accountNo,
        organizationId: params.organizationId,
      });
      const code = data.header?.code;
      if (code === 600) {
        const s3Link = data.body?.value;
        const link = document.createElement("a");
        link.href = s3Link;
        link.setAttribute("download", "report.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.success("Excel is downloading");
      } else {
        toast.error("No records found");
      }
    } catch (error) {
      console.error("Error during report download:", error);
      toast.error("An error occurred while downloading the report.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleMapView = () => {
    resetFilters();
    navigate("/maptask");
  };

  const handleGoCsv = () => {
    Cookies.set("viewMode", "list");
    resetFilters();
    navigate("/task/import-csv");
  };
  const handleGoCreateTask = () => {
    Cookies.set("viewmode", "list");
    resetFilters();
    navigate("/task/create-task");
  };
  const handleTaskHold = async (selectedtask) => {
    let uniqueTasks = Array.from(new Set(selectedtask));
    try {
      const response = await putTasksOnHold(uniqueTasks);
      if (response.header.code === 600) {
        toast.success("Task on hold");
        // const updatedTaskData = taskData.map((task) => {
        //   if (uniqueTasks.includes(task.taskId)) {
        //     return {
        //       ...task,
        //       taskStatus: "On_Hold",
        //       isChecked: false,
        //     };
        //   }
        //   return task;
        // });
        // setTaskData(updatedTaskData);
        fetchTasks({
          workflowId,
          currentPage: 1,
          pageSize: 10,
          priority: "Priority",
          active: "Status",
        }).then((fetchedTasks) => {
          console.log("Fetched tasks:", fetchedTasks);
          // Update taskData with the new fetched tasks
          setTaskData(fetchedTasks.taskList);
          setActive("Status");
          setPriority("Priority");
          setselectedStatusName("Status");
          setselectedPriorityName("Priority");
        });
        uniqueTasks = [];
      } else {
        toast.error("Not able to make task on hold");
      }
    } catch (error) {
      console.error("Error putting tasks on hold:", error);
    }
  };
  useEffect(() => {
    setAccountNo(""); // Reset account number
    sessionStorage.removeItem("taskSearchValue"); // Clear session storage on route change
  }, [location.pathname]); // Run only on route change

  // Retrieve saved account number when the component mounts
  useEffect(() => {
    const savedValue = sessionStorage.getItem("taskSearchValue");
    if (savedValue) {
      setAccountNo(savedValue);
    }
  }, []);

  const handleClick = () => {
    if (!isChecked) {
      toast.error("Please select a task before proceeding!");
    }
  };

  return (
    <>
      <Joyride
        steps={tasktollbar}
        run={runTour}
        continuous
        showSkipButton
        showProgress
        scrollToFirstStep
        styles={{
          options: {
            arrowColor: "#fff",
            zIndex: 10000,
            overlayColor: "rgba(0, 0, 0, 0.5)",
            primaryColor: "#4C3BCF",
            textColor: "#333",
          },
          tooltip: {
            borderRadius: "10px",
            transition: "transform 0.3s ease, opacity 0.3s ease",
          },
          buttonSkip: {
            color: "#4C3BCF",
          },
          buttonNext: {
            backgroundColor: "#4C3BCF",
            transition: "background-color 0.3s ease",
          },
          buttonBack: {
            color: "#4C3BCF",
          },
          spotlight: {
            transition: "box-shadow 0.5s ease",
          },
        }}
      />
      <div className="flex flex-col ">
        <div className="flex justify-between items-center pr-14">
          <div className="flex mt-1">
            <button
              onClick={handleMapView}
              className={`py-2 px-2  text-md rounded-l-md font-semibold border border-gray-300 transition-all duration-300 ${
                theme === "dark"
                  ? " dark-mode "
                  : theme === "high-contrast"
                  ? "bg-black "
                  : ""
              }`}
            >
              <img
                src={MapView}
                alt="mapview"
                className={`  ${
                  theme === "dark"
                    ? " filter brightness-2"
                    : theme === "high-contrast"
                    ? "filter brightness-200 contrast-200"
                    : "" // Default styling for white background
                }`}
              />
            </button>
            <button
              onClick={() => handleTabChange(2)}
              className={`py-2 px-2 rounded-r-md bg-blue-800 font-semibold border border-gray-100 transition-all duration-300 ${
                theme === "dark"
                  ? "bg-blue-800 "
                  : theme === "high-contrast"
                  ? "bg-black"
                  : ""
              }`}
            >
              <FiMenu className="text-white" />
            </button>
          </div>
          <div className="flex">
            <button
              type="button"
              className={`download text-white h-9 mt-1 bg-yellow-400 hover:bg-yellow-500 border border-gray-200 focus:ring-4 focus:outline-none
        focus:ring-gray-100 font-medium rounded-lg text-xs px-2 py-2.5 text-center inline-flex gap-2 items-center
        dark:focus:ring-gray-600 dark:border-gray-700 dark:text-white dark:hover:bg-yellow-500 dark:bg-yellow-400 me-2 ${
          theme === "dark" ? "" : theme === "high-contrast" ? "" : ""
        }`}
              onClick={handleShowModal}
            >
              <IoDownloadOutline size={20} className="text-white" />
              {translations[langMode].download}
            </button>
            {/* <button
              onClick={() => showDeleteModal("all", workflowId)}
              className={`deleteAll text-white h-9 mt-1 bg-red-600 hover:bg-red-500 border border-gray-200 focus:ring-4 focus:outline-none
                focus:ring-gray-100 font-medium rounded-lg  text-xs px-2 py-2.5 text-center inline-flex gap-2 items-center
                dark:focus:ring-gray-600 dark:border-gray-700 dark:text-white dark:bg-red-600 dark:hover:bg-red-500 me-2 ${
                  theme === "dark" ? "" : theme === "high-contrast" ? "" : ""
                }`}
            >
              <MdOutlineDeleteOutline size={20} />{" "}
              {translations[langMode].deleteAllRecord}
            </button> */}
            <button
              type="button"
              className={`createTask text-white h-9 mt-1 bg-gray-700 hover:bg-gray-600 border border-gray-200 focus:ring-4 focus:outline-none
              focus:ring-gray-100 font-medium rounded-lg  text-xs px-2 py-2.5 text-center inline-flex gap-2 items-center
              dark:focus:ring-gray-600 dark:border-gray-700 dark:text-white dark:hover:bg-gray-600 dark:bg-gray-700 me-2 ${
                theme === "dark" ? "" : theme === "high-contrast" ? "" : ""
              }`}
              onClick={handleGoCreateTask}
            >
              <img src={createImg} className="px-1" alt="create tmg" />
              {translations[langMode].createTask}
            </button>
            <button
              type="button"
              className={`gocsv text-white h-9 mt-1 bg-blue-800 hover:bg-indigo-600 border border-gray-200 focus:ring-4 focus:outline-none
              focus:ring-gray-100 font-medium rounded-lg  text-xs px-2 py-2.5 text-center inline-flex gap-2 items-center
              dark:focus:ring-gray-600 dark:border-gray-700 dark:bg-blue-800 dark:text-white dark:hover:bg-indigo-600 me-2 ${
                theme === "dark" ? "" : theme === "high-contrast" ? "" : ""
              }`}
              onClick={handleGoCsv}
            >
              <img src={importImg} className="px-1 h-5" alt="import cvs" />
              {translations[langMode].importCSV}
            </button>
            <Menu as="div" className="relative">
              <div>
                <button className="relative bottom-1">
                  <Menu.Button
                    className={`relative flex rounded-md p-2 text-md h-9 ${
                      theme === "dark"
                        ? "dark-mode border-white text-white border-2"
                        : theme === "high-contrast"
                        ? "bg-yellow-300 border-black text-black"
                        : "bg-white border-gray-800 text-black border-2"
                    }`}
                  >
                    <span />
                    <LuMoreVertical
                      size={20}
                      className={`${
                        theme === "dark"
                          ? "text-white"
                          : theme === "high-contrast"
                          ? "text-black"
                          : "text-black"
                      }`}
                    />
                  </Menu.Button>
                </button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className={` ${
                    theme === "dark"
                      ? "dark-mode border "
                      : theme === "high-contrast"
                      ? "high-contrast  "
                      : ""
                  } absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-lg bg-white py-1 shadow-md ring-1
                 ring-black ring-opacity-5 focus:outline-none`}
                >
                  <Menu.Item
                    className={` ${
                      theme === "dark"
                        ? "dark-mode hover:text-indigo-700 "
                        : theme === "high-contrast"
                        ? "high-contrast  "
                        : ""
                    }`}
                  >
                    {({ active }) => (
                      <Link
                        to=""
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "flex px-4 py-2 text-sm text-gray-700 gap-4 items-center text-nowrap"
                        )}
                        onClick={() => setOpen(true)}
                      >
                        <FaUser className="w-5" />
                        {translations[langMode].assign}
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item
                    className={` ${
                      theme === "dark"
                        ? "dark-mode hover:text-indigo-700 "
                        : theme === "high-contrast"
                        ? "high-contrast  "
                        : ""
                    }`}
                  >
                    {({ active }) => (
                      <Link
                        to=""
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "flex px-4 py-2 text-sm text-gray-700 gap-4 items-center text-nowrap"
                        )}
                        onClick={() => setOpenReassign(true)}
                      >
                        <FaRepeat className="w-5" />
                        {translations[langMode].reassign}
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item
                    className={` ${
                      theme === "dark"
                        ? "dark-mode hover:text-indigo-700 "
                        : theme === "high-contrast"
                        ? "high-contrast  "
                        : ""
                    }`}
                  >
                    {({ active }) => (
                      <Link
                        to=""
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "flex px-4 py-2 text-sm text-gray-700 gap-4 items-center text-nowrap"
                        )}
                        onClick={() => {
                          setUnassign(true);
                          setDeleteModalOpen(true);
                        }}
                      >
                        <TbUserCancel size={20} className="w-5" />
                        {translations[langMode].unassignn}
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item
                    className={` ${
                      theme === "dark"
                        ? "dark-mode hover:text-indigo-700 "
                        : theme === "high-contrast"
                        ? "high-contrast  "
                        : ""
                    }`}
                  >
                    {({ active }) => (
                      <Link
                        to=""
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "flex px-4 py-2 text-sm text-gray-700  gap-4 items-center"
                        )}
                        onClick={() => handleTaskHold(selectedtask)}
                      >
                        <FaCirclePause className="w-5" />
                        {translations[langMode].onhold}
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item
                    className={` ${
                      theme === "dark"
                        ? "dark-mode hover:text-indigo-700 "
                        : theme === "high-contrast"
                        ? "high-contrast  "
                        : ""
                    }`}
                  >
                    {({ active }) => (
                      <Link
                        to=""
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "px-4 py-2 text-sm text-gray-700 flex gap-4 items-center"
                        )}
                        onClick={() => showDeleteModal("single", null)}
                      >
                        <MdDelete className="w-5" />
                        {translations[langMode].delete}
                      </Link>
                    )}
                  </Menu.Item>
                  {(userType === "super_admin" || userType === "admin") && (
                    <Menu.Item
                      className={` ${
                        theme === "dark"
                          ? "dark-mode hover:text-indigo-700 "
                          : theme === "high-contrast"
                          ? "high-contrast  "
                          : ""
                      }`}
                    >
                      {({ active }) => (
                        <Link
                          to=""
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "flex px-4 py-2 text-sm text-gray-700 gap-4 items-center text-nowrap"
                          )}
                          onClick={() => showDeleteModal("all", workflowId)} // Call the delete all function
                        >
                          <MdOutlineDeleteOutline className="w-5" />
                          {translations[langMode].deleteAllRecord}
                        </Link>
                      )}
                    </Menu.Item>
                  )}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
        <div className="flex flex-row py-5  gap-2">
          <div
            className={` bg-white  ${
              theme === "dark"
                ? "dark-mode  "
                : theme === "high-contrast"
                ? "high-contrast  "
                : ""
            }`}
          >
            <div className="flex">
              <div className="relative mt-1">
                <input
                  id="account-search"
                  autoComplete="new-password" // Alternative to prevent autofill
                  type="text"
                  className={`block h-8 pt-2  text-sm text-gray-900 border border-gray-300 rounded-l-md  w-36
                   focus:ring-blue-300 focus:border-blue-300  
                    ${
                      theme === "dark"
                        ? "dark-mode  "
                        : theme === "high-contrast"
                        ? "high-contrast  "
                        : ""
                    }`}
                  placeholder={translations[langMode].search}
                  value={inputValue} // Bind temporary state to input
                  onChange={handleInputChange} // Update temporary state on input change
                />
              </div>

              <button
                onClick={handleSearch} // Update state on button click
                className="h-8 px-4 mt-1 bg-blue-800 text-white rounded-r-md hover:bg-blue-600"
              >
                <IoSearch />{" "}
              </button>
            </div>
          </div>

          <FilterSelect
            defaultText={translations[langMode].priority}
            options={priorityoptions}
            onChange={handlePrioritySelection}
            value={selectedPriorityName}
          />
          <FilterSelect
            defaultText={translations[langMode].status}
            options={activeoptions}
            onChange={handleStatusSelection}
            value={selectedStatusName}
          />
          <div
            className={` bg-white ${
              theme === "dark"
                ? "dark-mode  "
                : theme === "high-contrast"
                ? "high-contrast  "
                : ""
            }`}
          >
            <div className={`relative mt-1  `}>
              <div
                className={`absolute  inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none `}
              >
                <IoSearch />
              </div>
              <input
                type="text"
                id="table-search"
                className={`${
                  theme === "dark"
                    ? "dark-mode border "
                    : theme === "high-contrast"
                    ? "high-contrast border  "
                    : ""
                } block pt-2 ps-10 h-8 text-sm text-gray-900  border border-gray-300 rounded-lg w-48 focus:ring-blue-300 focus:border-blue-300   dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500  `}
                placeholder={translations[langMode].searchbycode}
                value={areaCode}
                onChange={handleAreaCodeChange}
              />
            </div>
          </div>
          <FilterSelect
            defaultText={translations[langMode].selectAssign}
            options={
              Array.isArray(fieldAgentList) && fieldAgentList.length > 0
                ? [
                    { id: "", value: "Default" },
                    ...fieldAgentList.map((agent) => ({
                      id: agent.userId,
                      value: agent.name,
                    })),
                  ]
                : [{ id: "", value: "Default" }]
            }
            value={selectedUserName}
            onChange={(selectedName) => {
              if (selectedName === "Default") {
                handleAssigneeChange("");
              } else {
                const selectedAgent = fieldAgentList.find(
                  (agent) => agent.name === selectedName
                );
                if (selectedAgent) {
                  handleAssigneeChange(selectedAgent.userId);
                }
              }
            }}
          />
          <SubActivity />
        </div>
      </div>
      <FieldListModal
        taskData={taskData}
        setTaskData={setTaskData}
        open={open}
        setOpen={setOpen}
        selectedtask={selectedtask}
        setIsChecked={setIsChecked}
        selectedOrgId={selectedOrgId}
        resetFilters={resetFilters}
      />
      <ReAssignModal
        taskData={taskData}
        setTaskData={setTaskData}
        openReassign={openReassign}
        setOpenReassign={setOpenReassign}
        selectedtask={selectedtask}
        setIsChecked={setIsChecked}
        selectedOrgId={selectedOrgId}
        resetFilters={resetFilters}
        setselectedStatusName={setselectedStatusName}
        setselectedPriorityName={setselectedPriorityName}
      />
      {deleteModalOpen && deleteItemType === "all" && (
        <DeletePermission
          deleteFunction={() => handleDeleteAllRecord(deleteItemId)}
          onClose={handleCloseDeleteModal}
          onDeleteText="Are you sure you want to delete all records?"
        />
      )}
      {deleteModalOpen && deleteItemType === "single" && (
        <DeletePermission
          deleteFunction={taskDeleteList}
          onClose={handleCloseDeleteModal}
          onDeleteText="Are you sure you want to delete records?"
        />
      )}
      <Modal
        setDownloadModal={setDownloadModal}
        downloadModal={downloadModal}
        downloadFiltration={downloadFiltration}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      {deleteModalOpen && unassign && (
        <DeletePermission
          onDeleteText="Are you sure you want to unassign this tasks ?"
          onClose={handleCloseDeleteModal}
          deleteFunction={handleUnassign} // Ensure selectedTask is passed correctly
        />
      )}
    </>
  );
};
export default TaskListToolbar;
