import axios from "axios";
import Cookies from "js-cookie"
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_BASE_BACKEND_API_URL;

// fetch asset data
export const fetchAssetDataTab = async (
  tab,
  pageSize,
  currentPage,
  filters,
  searchTerm,
  AssertIdSearch,
  selectedUserId,
  selectedOrgId
) => {
  const { vehicleType } = filters;
  const vehicleTypeFilter = vehicleType ? `&vehicleType=${vehicleType}` : "";
  const { assetType } = filters;
  const assetTypeFilter = assetType ? `&assertType=${assetType}` : "";
  const token = Cookies.get("accessToken");
  let endpoint = "";

  if (tab === 1) {
    endpoint = `${BASE_URL}api/v1/vehicle/get-all?page=${currentPage}&size=${pageSize}&vehicleId=${encodeURIComponent(searchTerm)}&assigneeId=${selectedUserId}${vehicleTypeFilter}&organizationId=${selectedOrgId}`;
  } else if (tab === 2) {
    endpoint = `${BASE_URL}api/v1/assert/get-all?page=${currentPage}&size=${pageSize}${assetTypeFilter}&assigneeId=${selectedUserId}&assertId=${AssertIdSearch}&organizationId=${selectedOrgId}`;
  }

  try {
    const response = await axios.get(endpoint, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.body.value;
  } catch (err) {
    console.error("Failed to fetch asset data:", err);
    throw new Error("Failed to load asset data. Please try again later.");
  }
};

//export asset report
export const handleAssetExportClick = async (fromDate, toDate ,selectedOrgId) => {
  const token = Cookies.get("accessToken");
  try {
    const formattedFromDate = new Date(fromDate)
      .toISOString()
      .replace("Z", "")
      .replace(/\)$/, "")
      .replace(/0+$/, "")
      .split(".")[0];
    const formattedToDate = new Date(toDate)
      .toISOString()
      .replace("Z", "")
      .replace(/\)$/, "")
      .replace(/0+$/, "")
      .split(".")[0];

    const response = await fetch(
      `${BASE_URL}api/v1/assert-log/generate-report?fromDate=${formattedFromDate}&toDate=${formattedToDate}&organizationId=${selectedOrgId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }

    const data = await response.json();
    if (data.header.code === 600 && data.body.value) {
      const viewerUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
        data.body.value
      )}`;
      window.open(viewerUrl, "_blank");
    } else if (data.header.code === 607) {
      toast.error("No Records Are Available");
    } else {
      toast.error("URL not provided in response.");
    }

    return data;
  } catch (error) {
    console.error("Error fetching report data:", error.message);
    throw error;
  }
};

//export vwehical report
export const handleVehicalExportClick = async (fromDate, toDate, selectedOrgId) => {
  const token = Cookies.get("accessToken");
  try {
    const formattedFromDate = new Date(fromDate)
      .toISOString()
      .replace("Z", "")
      .replace(/\)$/, "")
      .replace(/0+$/, "")
      .split(".")[0];
    const formattedToDate = new Date(toDate)
      .toISOString()
      .replace("Z", "")
      .replace(/\)$/, "")
      .replace(/0+$/, "")
      .split(".")[0];

    const response = await fetch(
      `${BASE_URL}api/v1/vehicle-log/generate-report?fromDate=${formattedFromDate}&toDate=${formattedToDate}&organizationId=${selectedOrgId}`, 
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }

    const data = await response.json();
    if (data.header.code === 600 && data.body.value) {
      const viewerUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
        data.body.value
      )}`;
      window.open(viewerUrl, "_blank");
    } else if (data.header.code === 607) {
      toast.error("No Records Are Available");
    } else {
      toast.error("URL not provided in response.");
    }

    return data;
  } catch (error) {
    console.error("Error fetching report data:", error.message);
    throw error;
  }
};


//field agent
export const fetchFieldAgents = async () => {
  const token = Cookies.get("accessToken");
  const response = await axios.get(`${BASE_URL}api/v1/user/field-agents`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.body.value || [];
};

//add asset

export const addAsset = async (formData) => {
  const token = Cookies.get("accessToken");

  try {
    const response = await axios.post(
      `${BASE_URL}api/v1/assert/update`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.status === 200) {
      throw new Error("Something went wrong with the API call.");
    }

    const data = response.data;
    if (data.header.code === 600) {
      toast.success("Asset Added Successfully");
      return data;
    }
  } catch (error) {
    console.error("Failed to add asset:", error);
    toast.error("Failed to add asset");
    throw error;
  }
};
//add vehical
export const addVehical = async(formData)=>{
  const token = Cookies.get("accessToken");
  try {
    const response = await axios.post(
      `${BASE_URL}api/v1/vehicle/update`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.status === 200) {
      throw new Error("Something went wrong with the API call.");
    }

    const data = response.data;
    if (data.header.code === 600) {
      toast.success("vehical Added Successfully");
      return data;
    }
  } catch (error) {
    console.error("Failed to add vehical:", error);
    toast.error("Failed to add vehical");
    throw error;
  }
}

//get vehical by id

const mapVehicleIdToApiId = (vehicleId) => {
  if (!vehicleId) return null; // If vehicleId is undefined, return null or handle the case appropriately
  const numericalPart = vehicleId.substring(1); // Remove the "V" prefix
  const Id = parseInt(numericalPart); // Parse the numerical part to integer
  return Id;
};

export const fetchVehicleData = async (vehicleId) => {
  const token = Cookies.get('accessToken');
  const Id = mapVehicleIdToApiId(vehicleId);
  try {
    const response = await fetch(`${BASE_URL}api/v1/vehicle/get?id=${Id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching vehicle data:', error);
    throw error;
  }
};

//update vehical

export const updateVehicleData = async (formData) => {
  const token = Cookies.get('accessToken');
  if (!token) {
    console.error('Authentication token not found.');
    return;
  }
  try {
    const response = await fetch(`${BASE_URL}api/v1/vehicle/update`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to update vehicle:', error);
    throw error;
  }
};

//update asset

const mapassertIdToApiId = (assertId) => {
  if (!assertId) return null; // If assertId is undefined, return null or handle the case appropriately
  // Assuming vehicle ID format is "Vxxxx", where xxxx represents the numerical part
  const numericalPart = assertId.substring(1); // Remove the "V" prefix
  const Id = parseInt(numericalPart); // Parse the numerical part to integer
  return Id;
};

export const fetchAssetData = async (assertId) => {
  const token = Cookies.get('accessToken');
  const Id = mapassertIdToApiId(assertId);
  try {
    const response = await fetch(`${BASE_URL}api/v1/assert/get?id=${Id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching asset data:', error);
    throw error;
  }
};


//update asset

export const updateAssetData = async (formData) => {
  const token = Cookies.get('accessToken');
  if (!token) {
    console.error('Authentication token not found.');
    return;
  }
  try {
    const response = await fetch(`${BASE_URL}api/v1/assert/update`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to update asset:', error);
    throw error;
  }
};

//assign vehical

export const assignVehicle = async (id, vehicleId, fieldAgentId) => {
  const token = Cookies.get('accessToken');
  try {
    const response = await axios.post(
      `${BASE_URL}api/v1/vehicle/assign-vehicle`,
      { id, vehicleId, fieldAgentId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

//assign assert

export const assignInventory = async (id, assetId, assetName, selectedAgent) => {
  const token = Cookies.get('accessToken');
  try {
    const response = await axios.post(
      `${BASE_URL}api/v1/assert/assign`,
      { id, assetId, assetName,  fieldAgentId: selectedAgent },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};