import React, { useContext, useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp, IoMdAddCircle } from "react-icons/io";
import { FaCircleCheck, FaCircleExclamation } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import {
  fetchCompletionRules,
  createCompletionRule,
  deleteCompletionRule,
  updateRule,
} from "../../service/Configuration";
import AppContext from "../../context/AppContext";
import { fetchDynamicWorkflows } from "../../service/UserDashboard";
import FilterSelect from "../common/FilterSelect";
import { toast } from "react-toastify";
import translations from "../common/Translations";

const highlightText = (text) => {
  if (typeof text !== "string") {
    text = "";
  }
  const stringRegex = /(".*?"|'.*?')/g;
  const keywordRegex =
    /\b(const|let|var|function|return|if|else|for|while|true|false|null|in|and)\b/g;
  const numberRegex = /\b(\d+)\b/g;
  const operatorRegex = /(\+|\-|\*|\/|\=|\!|\&|\||\?|\:|\%)/g;
  const tokens = text.split(/(\s+)/);
  const wrapSpan = (token, regex, className) => {
    return token.replace(regex, `<span class="${className}">$1</span>`);
  };
  const highlightedTokens = tokens.map((token) => {
    if (stringRegex.test(token)) {
      return wrapSpan(token, stringRegex, "text-green-500");
    } else if (keywordRegex.test(token)) {
      return wrapSpan(token, keywordRegex, "text-blue-500");
    } else if (numberRegex.test(token)) {
      return wrapSpan(token, numberRegex, "text-purple-500");
    } else if (operatorRegex.test(token)) {
      return wrapSpan(token, operatorRegex, "text-red-500");
    } else {
      return token;
    }
  });
  return highlightedTokens.join("");
};

const HighlightedTextarea = ({ value, onChange }) => {
  return (
    <div className="relative">
      <div
        className="absolute inset-0 m-0 overflow-auto p-3 pointer-events-none whitespace-pre-wrap break-words"
        aria-hidden="true"
        dangerouslySetInnerHTML={{ __html: highlightText(value) }}
      />
      <textarea
        value={value}
        onChange={onChange}
        rows={5}
        className="relative w-full h-full shadow-inner bg-transparent border-1 p-3 outline-none text-transparent rounded-md   caret-black"
        />
    </div>
  );
};

export const CompletionRules = () => {
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflowId, setSelectedWorkflowId] = useState("");
  const [selectedWorkflowName, setSelectedWorkflowName] = useState("");
  const { selectedOrgId, langMode } = useContext(AppContext);
  const [criteria, setCriteria] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(
    <FaCircleCheck className="text-green-500" />
  );
  const { theme } = useContext(AppContext);
  const [completionRules, setCompletionRules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(1);
  const [createrule, setcreateRule] = useState(false);
  const [status, setStatus] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [iconStatus, setIconstatus] = useState("");
  const [ruleid, setRuleId] = useState();

  const statusoptions = [
    { id: "SUBMISSION", value: "SUBMISSION" },
    { id: "NOT_COMPLETED", value: "NOT_COMPLETED" },
  ];

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  const handleIconSelect = (icon, iconMsg) => {
    setSelectedIcon(icon);
    setIconstatus(iconMsg);
    setDropdownOpen(false);
  };

  const loadCompletionRules = async (reset = false) => {
    setLoading(true);
    setError(null);
    try {
      const rules = await fetchCompletionRules(selectedWorkflowId, page, size);
      const uniqueRules = reset
        ? rules
        : rules.filter(
            (newRule) =>
              !completionRules.find(
                (existingRule) => existingRule.id === newRule.id
              )
          );

      setCompletionRules((prevRules) =>
        reset ? uniqueRules : [...prevRules, ...uniqueRules]
      );
      setHasMore(uniqueRules.length === size);
    } catch (error) {
      console.error("Error loading completion rules:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedWorkflowId) {
      loadCompletionRules();
    }
  }, [selectedWorkflowId]);

  const handleWorkflowChange = (selectedName) => {
    const selectedWorkflow = workflows.find(
      (workflow) => workflow.workflowName === selectedName
    );
    if (selectedWorkflow) {
      setSelectedWorkflowName(selectedWorkflow.workflowName);
      setSelectedWorkflowId(selectedWorkflow.workflowId);
      setCompletionRules([]); // Reset completionRules to empty array
      setPage(0); // Reset page number
      setSize(2); // Reset page size
      setHasMore(true); // Reset hasMore flag
    }
  };

  useEffect(() => {
    if (selectedOrgId) {
      const loadWorkflows = async () => {
        try {
          const workflows = await fetchDynamicWorkflows(selectedOrgId);
          setWorkflows(Array.isArray(workflows) ? workflows : []);
          console.log(workflows)
        } catch (error) {
          console.error("Error loading workflows:", error);
          setWorkflows([]); // Set to an empty array on error
        }
      };
      loadWorkflows();
    }
  }, [selectedOrgId]);

  const handlCreateRule = () => {
    if (!selectedWorkflowId) {
      toast.error("Please select a workflow before adding rule");
      return;
    }
    setcreateRule((prevShowCriteria) => !prevShowCriteria);
  };
  // save changes
  const handleSaveChanges = async () => {
    if (!selectedWorkflowId || !criteria || !status) {
      toast.error("Please fill all fields before saving");
      return;
    }
    try {
      const responseData = await createCompletionRule(
        selectedWorkflowId,
        criteria,
        status,
        iconStatus,
        isActive
      );
      if (responseData.header.code === 600) {
        setStatus("");
        setCriteria("");
        toast.success("Rule Created Successfully");
        loadCompletionRules(); // Reload the rules after creating a new one
      } else {
        toast.error(responseData.header.body.value);
      }
    } catch (error) {
      console.error("Error creating completion rule:", error);
      toast.error("Failed to create rule");
    }
  };

  const handleDeleteRule = async (ruleid) => {
    try {
      const response = await deleteCompletionRule(ruleid);
      if (response.header.code === 600) {
        toast.success("Rule deleted successfully");
        setCompletionRules((prevRules) =>
          prevRules.filter((rule) => rule.id !== ruleid)
        );
      } else {
        toast.error("Failed to delete rule");
      }
    } catch (error) {
      console.error("Error deleting completion rule:", error);
      toast.error("Failed to delete rule");
    }
  };

  const loadMore = () => {
    if (!isLoading && hasMore) {
      setPage((prevPage) => prevPage + 1); // Increment page by 1
    }
  };

  useEffect(() => {
    if (page > 0) {
      loadCompletionRules();
    }
  }, [page]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        !isLoading &&
        hasMore &&
        window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.offsetHeight - 200
      ) {
        loadMore();
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isLoading, hasMore]); // Watch isLoading and hasMore for changes

  const handleStatus = (selectedStatus) => {
    setStatus(selectedStatus);
    if (selectedStatus === "SUBMISSION") {
      setSelectedIcon(<FaCircleCheck className="text-green-500" />);
      setIconstatus("SUBMISSION");
    } else if (selectedStatus === "NOT_COMPLETED") {
      setSelectedIcon(<FaCircleExclamation className="text-yellow-400" />);
      setIconstatus("NOT_COMPLETED");
    }
  };
  //update
  const handleStatusChange = (e, index) => {
    const { value } = e.target;
    setCompletionRules((prevRules) => {
      const updatedRules = [...prevRules];
      updatedRules[index] = {
        ...updatedRules[index],
        status: value,
        icon: value === "SUBMISSION" ? "SUBMISSION" : "NOT_COMPLETED", // Adjust as per your logic
      };
      return updatedRules;
    });
  };
  const handleActiveChange = (index) => {
    setCompletionRules((prevRules) => {
      const updatedRules = [...prevRules];
      updatedRules[index] = {
        ...updatedRules[index],
        activeStatus: !updatedRules[index].activeStatus,
      };
      return updatedRules;
    });
  };

  const handleRuleChange = (e, index) => {
    const updatedRules = [...completionRules];
    updatedRules[index].rule = e.target.value;
    setCompletionRules(updatedRules);
  };
  const handleUpdateChanges = async (
    ruleid,
    status,
    iconStatus,
    criteria,
    selectedWorkflowId,
    isActive
  ) => {
    try {
      const response = await updateRule(
        ruleid,
        status,
        iconStatus,
        criteria,
        selectedWorkflowId,
        isActive
      );
      if (response.header.code === 600) {
        toast.success("Rule updated successfully");
        loadCompletionRules(); // Reload the rules after updating
      } else {
        toast.error("Failed to update rule");
      }
    } catch (error) {
      console.error("Error updating completion rule:", error);
      toast.error("Failed to update rule");
    }
  };
  return (
    <>
      <div className="flex justify-between flex-wrap gap-3">
        <FilterSelect
          defaultText="Workflows"
          options={workflows.map((workflow) => ({
            id: workflow.workflowId,
            value: workflow.workflowName,
          }))}
          value={selectedWorkflowName}
          onChange={(selectedName) => handleWorkflowChange(selectedName)}
        />
        <div className="flex justify-between items-center">
          <button
            type="button"
            onClick={handlCreateRule}
            className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-800 rounded-lg hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <IoMdAddCircle className="text-md mr-2" />{" "}
            {translations[langMode].createrule}
          </button>
        </div>
      </div>

      <div className="">
        {createrule && (
          <>
            <div
              className={`bg-white border-2 border-blue-300 shadow-2xl rounded-md w-full px-10 p-4 my-3 ${
                theme === "dark"
                  ? "dark-mode border "
                  : theme === "high-contrast"
                  ? "high-contrast  "
                  : ""
              }`}
            >
              <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="">
                  <label
                    htmlFor="first-name"
                    className={`block text-sm font-semibold leading-6 text-gray-900 ${
                      theme === "dark"
                        ? "dark-mode  "
                        : theme === "high-contrast"
                        ? "high-contrast  "
                        : ""
                    }`}
                  >
                    {translations[langMode].status}
                  </label>
                  <div className=" flex flex-wrap">
                    <FilterSelect
                      defaultText={"Status"}
                      options={statusoptions}
                      value={status}
                      onChange={handleStatus}
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="first-name"
                    className={`block text-sm font-semibold leading-6 text-gray-900 ${
                      theme === "dark"
                        ? "dark-mode  "
                        : theme === "high-contrast"
                        ? "high-contrast  "
                        : ""
                    }`}
                  >
                    {translations[langMode].activedeactive}
                  </label>
                  <div className="">
                    <input
                      type="checkbox"
                      id="toggle-switch"
                      name="toggle-switch"
                      checked={isActive}
                      onChange={handleToggle}
                      className="hidden"
                    />
                    <label
                      htmlFor="toggle-switch"
                      className={`block w-20 px-2 rounded-full border-0  py-2 text-gray-900 shadow-sm  cursor-pointer ${
                        isActive ? "bg-indigo-500" : "bg-indigo-300"
                      }`}
                    >
                      <span
                        className={`block w-10 h-6 bg-white rounded-full shadow-md transform transition-transform ${
                          isActive ? "translate-x-5" : "translate-x-0"
                        }`}
                      />
                    </label>
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="message"
                    className={`block text-sm font-semibold leading-6 text-gray-900 ${
                      theme === "dark"
                        ? "dark-mode  "
                        : theme === "high-contrast"
                        ? "high-contrast  "
                        : ""
                    }`}
                  >
                    {translations[langMode].criteria}
                  </label>
                  <div className="w-full">
                    <HighlightedTextarea
                      value={criteria}
                      onChange={(e) => setCriteria(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-center p-2">
                <div className="flex items-center gap-2 relative">
                  <span>{translations[langMode].icon}</span>
                  {selectedIcon}
                </div>

                <div className="flex">
                  <IoIosArrowUp />
                  <IoIosArrowDown />
                </div>
              </div>
              <button
                onClick={handleSaveChanges}
                className=" text-sm px-4 py-2 bg-blue-800 text-white rounded-md hover:bg-blue-600"
              >
                {translations[langMode].save}
              </button>
            </div>
          </>
        )}

        {error && <p> {error.message}</p>}
        {/* {completionRules.length === 0 && (
          <p className="text-center"> {translations[langMode].norule}</p>
        )} */}
        {!loading && !error && completionRules.length > 0 ? (
          <div className="">
            {completionRules.map((rule, index) => (
              <div
                className={`w-full bg-white border-2 border-blue-300 shadow-2xl rounded-md  px-10 my-7 p-5 ${
                  theme === "dark"
                    ? "dark-mode border  "
                    : theme === "high-contrast"
                    ? "high-contrast  "
                    : ""
                }`}
                key={index}
              >
                <RxCross2
                  className="float-right"
                  onClick={() => handleDeleteRule(rule.id)}
                />
                  <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4">
                  <div>
                    <label
                      htmlFor="rule-status"
                      className={`block text-sm font-semibold leading-6 text-gray-900 ${
                        theme === "dark"
                          ? "dark-mode  "
                          : theme === "high-contrast"
                          ? "high-contrast  "
                          : ""
                      }`}
                    >
                      {translations[langMode].status}
                    </label>
                    <div className="">
                      <select
                        id={`rule-status-${index}`}
                        name={`rule-status-${index}`}
                        value={rule.status}
                        onChange={(e) => handleStatusChange(e, index)}
                        className={`block w-80 rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
                          theme === "dark"
                            ? "dark-mode  "
                            : theme === "high-contrast"
                            ? "high-contrast  "
                            : ""
                        }`}
                      >
                        <option value="SUBMISSION">SUBMISSION</option>
                        <option value="NOT_COMPLETED">NOT_COMPLETED</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="first-name"
                      className={`block text-sm font-semibold leading-6 text-gray-900 ${
                        theme === "dark"
                          ? "dark-mode  "
                          : theme === "high-contrast"
                          ? "high-contrast  "
                          : ""
                      }`}
                    >
                      {translations[langMode].activedeactive}
                    </label>
                    <div className="">
                      <input
                        type="checkbox"
                        id={`toggle-switch-${index}`}
                        name={`toggle-switch-${index}`}
                        checked={rule.activeStatus}
                        onChange={() => handleActiveChange(index)}
                        className="hidden"
                      />
                      <label
                        htmlFor={`toggle-switch-${index}`}
                        className={`block w-20 px-2 rounded-full border-0 py-2 text-gray-900 shadow-sm cursor-pointer ${
                          rule.activeStatus ? "bg-indigo-500" : "bg-indigo-300"
                        }`}
                      >
                        <span
                          className={`block w-10 h-6 bg-white rounded-full shadow-md transform transition-transform ${
                            rule.activeStatus
                              ? "translate-x-5"
                              : "translate-x-0"
                          }`}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="rule-criteria"
                      className={`block text-sm font-semibold leading-6 text-gray-900 ${
                        theme === "dark"
                          ? "dark-mode  "
                          : theme === "high-contrast"
                          ? "high-contrast  "
                          : ""
                      }`}
                    >
                      {translations[langMode].criteria}
                    </label>
                    <div className="">
                      <HighlightedTextarea
                        value={rule.rule}
                        onChange={(e) => handleRuleChange(e, index)}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center p-2">
                  <div className="flex items-center gap-2 relative">
                    <span>{translations[langMode].icon}</span>
                    {rule.icon === "SUBMISSION" ? (
                      <FaCircleCheck className="text-green-500" />
                    ) : (
                      <FaCircleExclamation className="text-yellow-400" />
                    )}
                  </div>
                  <div className="flex">
                    <IoIosArrowUp />
                    <IoIosArrowDown />
                  </div>
                </div>
                <button
                  onClick={() =>
                    handleUpdateChanges(
                      rule.id,
                      rule.status,
                      rule.icon,
                      rule.rule,
                      selectedWorkflowId,
                      rule.activeStatus
                    )
                  }
                  className="text-sm px-4 py-2 bg-blue-800 text-white rounded-md hover:bg-blue-600"
                >
                  {translations[langMode].update}
                </button>
              </div>
            ))}
          </div>
        ) : (
          <>
            {isLoading && <p>Loading...</p>}
            {!hasMore && (
              <p className="text-center"> {translations[langMode].norule}</p>
            )}
          </>
        )}
      </div>
    </>
  );
};
