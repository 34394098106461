import axios from "axios";
import Cookies from 'js-cookie'

const BASE_URL = process.env.REACT_APP_BASE_BACKEND_API_URL;

export const fetchTaskDetails = async (taskId) => {
  const token = Cookies.get("accessToken");
  return fetch(`${BASE_URL}api/v1/task/web/get-task?taskId=${taskId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      const taskDetail = data.body.value;
      if (taskDetail.taskDetails) {
        taskDetail.taskDetailsParsed = JSON.parse(taskDetail.taskDetails);
      }
      return taskDetail;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      throw error; // Propagate the error further if needed
    });
};

// Function to fetch field agents
export const fetchFieldAgents = async () => {
  const token = Cookies.get("accessToken");
  return axios.get(`${BASE_URL}api/v1/user/field-agents`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.data.body.value;
    })
    .catch((error) => {
      console.error("An error occurred:", error);
      throw error; // Propagate the error further if needed
    });
};

// Function to assign task to field agent
export const assignTaskToAgent = async (taskIds, selectedFieldAgent, workflowId) => {
  const token = Cookies.get("accessToken");
  const requestData = {
    taskIdList: taskIds,
    fieldAgentId: selectedFieldAgent,
    workflowId: workflowId,
  };

  return axios.post(`${BASE_URL}api/v1/task/multi-assign`, requestData, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error:", error);
      throw error; // Propagate the error further if needed
    });
};

// Function to fetch tasks based on filters
export const fetchTasksByFilters = async (workflowId, taskStatus) => {
  const token = Cookies.get("accessToken");
  return axios.get(`${BASE_URL}api/v1/task/map/get-tasks?workflowId=${workflowId}&taskStatus=${taskStatus}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.data.body.value.taskList.map((task) => ({
        lat: parseFloat(task.latitude),
        lng: parseFloat(task.longitude),
        taskid: task.taskId,
        accountNo: task.accountNo,
      }));
    })
    .catch((error) => {
      console.error("An error occurred:", error);
      throw error; // Propagate the error further if needed
    });
};

export const getTaskAPI = async (workflowId, taskStatus, assigneeId = "") => {
  const token = Cookies.get("accessToken");
  try {
    // Constructing the URL with workflowId, taskStatus, and optional assigneeId
    const url = `${BASE_URL}api/v1/task/map/get-tasks?workflowId=${workflowId}&taskStatus=${taskStatus}${assigneeId ? `&assigneeId=${assigneeId}` : ""
      }`;

    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching tasks:", error);
    return null;
  }
};

export const getTaskAPI2 = async (workflowId, taskStatus, assigneeId = "") => {
  const token = Cookies.get("accessToken");
  try {
    // Constructing the URL with optional assigneeId
    const url = `${BASE_URL}api/v1/task/map/get-tasks?workflowId=${workflowId}&taskStatus=${assigneeId ? `&assigneeId=${assigneeId}` : ""
      }`;

    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching tasks:", error);
    return null;
  }
};
