import React, { useContext, useEffect, useState, useCallback } from "react";
import AppContext from "../../context/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GetTaskHistory } from "../../service/Task";
import { fetchTaskDetail, updateTaskDetails } from "../../service/Task";
import { FaArrowLeft, FaUserEdit } from "react-icons/fa";
import translations from "../../components/common/Translations";
import debounce from "lodash.debounce";

const TaskDetailPage = () => {
  const { taskId } = useParams();
  const [loading, setLoading] = useState(true);
  const [fieldData, setFieldData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [taskHistory, setTaskHistory] = useState([]);
  const [page, setPage] = useState(0);
  const [task, setTask] = useState(null);
  const [size] = useState(10);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);
  const [expandedTask, setExpandedTask] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [taskDetails, setTaskDetails] = useState({});
  const context = useContext(AppContext);
  const { accountNo, theme, langMode, userType, setKey } = context;

  useEffect(() => {
    fetchTaskDetails();
  }, [taskId]);

  const fetchTaskDetails = async () => {
    try {
      const data = await fetchTaskDetail(taskId);
      const task = data.body.value.task;
      const taskDetail = JSON.parse(task.taskDetails);
      setTaskDetails(taskDetail);
      const additionalFields = {
        TaskID: task.taskId,
        "Account No.": task.accountNo,
        Activity: task.taskDetails.Activity,
      };
      const otherFields = {
        Priority: task.priority,
        Status: task.taskStatus,
        "Created Date": task.createdDate,
        Latitude: task.latitude,
        Longitude: task.longitude,
        "Area Code": task.areaCode,
        Tariff: task.tariff,
        Office: task.office,
        Area: task.area,
      };
      const fieldValues = [
        ...Object.entries(additionalFields).map(([fieldName, value]) => ({
          fieldName,
          value,
        })),
        ...Object.entries(otherFields).map(([fieldName, value]) => ({
          fieldName,
          value,
        })),
        ...Object.entries(taskDetails).map(([fieldName, value]) => ({
          fieldName,
          value,
        })),
      ];
      setTask(task);
      setFieldData(fieldValues);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching task details:", error);
      setLoading(false);
    }
  };

  const navigate = useNavigate();

  const fetchTaskHistory = useCallback(async () => {
    if (!accountNo || !hasMore) return; // Prevent fetching if there are no more results
  
    setIsLoading(true);
    try {
      const responseData = await GetTaskHistory(accountNo, page, size);
      
      if (responseData === 'No Data Found') {
        setHasMore(false); // Stop further API calls
        setIsLoading(false);
        toast.error("No more data to load")
        return; // Stop execution if no data is found
      }
  
      if (!Array.isArray(responseData)) {
        throw new Error("Response data is not an array");
      }
  
      setTaskHistory((prevHistory) => {
        const existingTaskIds = new Set(prevHistory.map((task) => task.taskHistoryId));
        const uniqueTasks = responseData.filter((task) => !existingTaskIds.has(task.taskHistoryId));
        return [...prevHistory, ...uniqueTasks];
      });
      
    } catch (error) {
      console.error("Error fetching task history:", error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [accountNo, page, size, hasMore]);
  

  useEffect(() => {
    fetchTaskHistory();
  }, [fetchTaskHistory]);

  const loadMore = () => {
    if (!isLoading && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleScroll = useCallback(
    debounce(() => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 200
      ) {
        loadMore();
      }
    }, 300),
    [isLoading, hasMore]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {}, [taskHistory]);

  const handleConfirm = async () => {
    try {
      console.log("Current fieldData:", fieldData);
      const updatedTaskDetails = fieldData.reduce((acc, field) => {
        acc[field.fieldName] = field.value;
        return acc;
      }, {});
      updatedTaskDetails["Activity"] =
        taskDetails?.Activity || "Default_Activity";

      updatedTaskDetails["Priority"] = task.priority || "Low_Priority";
      const updatedTask = {
        taskId: task.taskId,
        workflowId: task.workflowId,
        assigneeId: task.assigneeId,
        priority: task.priority || "Low_Priority",
        taskDetails: JSON.stringify(updatedTaskDetails),
        taskStatus: task.taskStatus,
        assignedBy: task.assignedBy,
        createdDate: task.createdDate,
        latitude: task.longitude,
        longitude: task.latitude,
        areaCode: task.areaCode,
        tariff: task.tariff,
        accountNo: task.accountNo,
        createdBy: task.createdBy,
        finishedDate: task.finishedDate,
        office: task.office,
        area: task.area,
        source: task.source,
        icon: task.icon,
        completed: task.completed,
        assigned: task.assigned,
        deleted: task.deleted,
      };
      const data = await updateTaskDetails(updatedTask);
      if (data.header.code === 600) {
        toast.success("Task details updated successfully!");
        setEditMode(false);
      } else {
        toast.error(data.body.value || "Failed to update task details");
      }
    } catch (error) {
      alert(error.message);
    }
  };
  const handleEditClick = () => {
    setEditMode(!editMode);
  };

  const handleDiscard = () => {
    fetchTaskDetails();
    setEditMode(false);
  };
  const handleToggle = (index) => {
    setExpandedTask(expandedTask === index ? null : index);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleDateString(undefined, options);
  };
  const isValidURL = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleGoBack = () => {
    setKey((prevKey) => prevKey + 1); // Update the key to force re-render
    sessionStorage.setItem("resetSearch", "true");
    navigate("/task-manage"); // Or the route to go back
  };

  const handleAccountNoChange = (e) => {
    const newAccountNo = e.target.value;
    setTask((prev) => ({ ...prev, accountNo: newAccountNo }));
    setFieldData((prev) =>
      prev.map((field) =>
        field.fieldName === "Account No."
          ? { ...field, value: newAccountNo }
          : field
      )
    );
  };
  const handleLatitudeChange = (e) => {
    const newLatitude = e.target.value;
    setTask((prev) => ({ ...prev, latitude: newLatitude }));
    setFieldData((prev) =>
      prev.map((field) =>
        field.fieldName === "Latitude"
          ? { ...field, value: newLatitude }
          : field
      )
    );
  };

  const handlePriorityChange = (e) => {
    const newPriority = e.target.value;
    setTask((prev) => ({ ...prev, priority: newPriority }));
    setFieldData((prev) =>
      prev.map((field) =>
        field.fieldName === "Priority"
          ? { ...field, value: newPriority }
          : field
      )
    );
  };
  const handleAreacodeChange = (e) => {
    const newAreaCode = e.target.value;
    setTask((prev) => ({ ...prev, areaCode: newAreaCode }));
    setFieldData((prev) =>
      prev.map((field) =>
        field.fieldName === "Area Code"
          ? { ...field, value: newAreaCode }
          : field
      )
    );
  };

  const handleTarrifChange = (e) => {
    const newtariff = e.target.value;
    setTask((prev) => ({ ...prev, tariff: newtariff }));
    setFieldData((prev) =>
      prev.map((field) =>
        field.fieldName === "Tariff" ? { ...field, value: newtariff } : field
      )
    );
  };

  const handleOfficeChange = (e) => {
    const newoffice = e.target.value;
    setTask((prev) => ({ ...prev, office: newoffice }));
    setFieldData((prev) =>
      prev.map((field) =>
        field.fieldName === "Office" ? { ...field, value: newoffice } : field
      )
    );
  };
  const handleAreaChange = (e) => {
    const newarea = e.target.value;
    setTask((prev) => ({ ...prev, area: newarea }));
    setFieldData((prev) =>
      prev.map((field) =>
        field.fieldName === "Area" ? { ...field, value: newarea } : field
      )
    );
  };
  const handleLongitudeChange = (e) => {
    const newLongitude = e.target.value;
    setTask((prev) => ({ ...prev, longitude: newLongitude }));
    setFieldData((prev) =>
      prev.map((field) =>
        field.fieldName === "Longitude"
          ? { ...field, value: newLongitude }
          : field
      )
    );
  };
  return (
    <div className="w-full">
      <div className="flex">
        <div
          onClick={handleGoBack}
          className={`p-2 rounded-full border cursor-pointer w-8 m-3 
        ${
          theme === "dark"
            ? "bg-gray-800 border-gray-600 hover:bg-gray-700"
            : ""
        } 
        ${
          theme === "high-contrast"
            ? "high-contrast border border-gray-600"
            : ""
        } 
        ${
          theme !== "dark" && theme !== "high-contrast"
            ? "border-gray-400 hover:bg-gray-200"
            : ""
        } `}
        >
          <FaArrowLeft
            className={`h-3 w-3 ${
              theme === "dark"
                ? "text-white"
                : theme === "high-contrast"
                ? "text-black"
                : "text-gray-700"
            }`}
          />
        </div>
        <h4
          className={`${
            theme === "dark"
              ? "dark-mode "
              : theme === "high-contrast"
              ? "high-contrast  "
              : ""
          } text-sm uppercase mt-4 font-semibold text-gray-800`}
        >
          {translations[langMode].taskdetail}
        </h4>
      </div>
      <div
        className={`bg-white px-5 py-5 rounded-md   ${
          theme === "dark"
            ? "dark-mode"
            : theme === "high-contrast"
            ? "high-contrast"
            : ""
        }`}
      >
        <div className="flex gap-4">
          <div
            className={`w-[700px] bg-gray-100 rounded-md shadow-lg border border-blue-300  ${
              theme === "dark"
                ? "dark-mode"
                : theme === "high-contrast"
                ? "high-contrast"
                : ""
            }`}
          >
            <div className="px-4 py-2 flex justify-between ">
              <h3
                className={`${
                  theme === "dark"
                    ? "dark-mode "
                    : theme === "high-contrast"
                    ? "high-contrast  "
                    : ""
                } text-sm font-semibold leading-7 text-gray-900`}
              >
                {translations[langMode].taskdetail}
              </h3>
              {userType !== "field_agent" && (
                <span className=" rounded-full">
                  <FaUserEdit
                    onClick={handleEditClick}
                    className={`h-6 w-6 cursor-pointer ${
                      editMode ? "text-green-500" : "text-black"
                    } ${
                      theme === "dark"
                        ? "dark-mode "
                        : theme === "high-contrast"
                        ? "high-contrast  "
                        : ""
                    }`}
                  />
                </span>
              )}
            </div>
            <div
              className={`p-6 border-t border-gray-200 bg-white rounded-lg shadow-sm   ${
                theme === "dark"
                  ? "dark-mode"
                  : theme === "high-contrast"
                  ? "high-contrast"
                  : ""
              }`}
            >
              <dl className="divide-y divide-gray-200">
                {loading ? (
                  <div className="text-center text-gray-500">Loading...</div>
                ) : (
                  <>
                    <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-4">
                      <dt
                        className={`text-sm font-semibold text-gray-700 ${
                          theme === "dark"
                            ? "dark-mode "
                            : theme === "high-contrast"
                            ? "high-contrast  "
                            : ""
                        }`}
                      >
                        Task ID
                      </dt>
                      <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">
                        {editMode ? (
                          <input
                            type="text"
                            className={`w-full border border-gray-300 focus:border-blue-500
                               focus:ring-2 focus:ring-blue-200 rounded-md p-2 ${
                                 theme === "dark"
                                   ? "dark-mode "
                                   : theme === "high-contrast"
                                   ? "high-contrast  "
                                   : ""
                               }`}
                            value={task.taskId}
                            disabled
                          />
                        ) : (
                          <span
                            className={`inline-block rounded-md px-3 py-1 text-gray-700 ${
                              theme === "dark"
                                ? "dark-mode "
                                : theme === "high-contrast"
                                ? "high-contrast  "
                                : ""
                            }`}
                          >
                            {task.taskId}
                          </span>
                        )}
                      </dd>
                    </div>
                    <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-4">
                      <dt
                        className={`text-sm font-semibold text-gray-700 ${
                          theme === "dark"
                            ? "dark-mode "
                            : theme === "high-contrast"
                            ? "high-contrast  "
                            : ""
                        }`}
                      >
                        Account No.
                      </dt>
                      <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">
                        {editMode ? (
                          <input
                            type="text"
                            className={`w-full border border-gray-300 focus:border-blue-500
                               focus:ring-2 focus:ring-blue-200 rounded-md p-2 ${
                                 theme === "dark"
                                   ? "dark-mode "
                                   : theme === "high-contrast"
                                   ? "high-contrast  "
                                   : ""
                               }`}
                            value={task.accountNo}
                            onChange={handleAccountNoChange}
                          />
                        ) : (
                          <span
                            className={`inline-block rounded-md px-3 py-1 text-gray-700 ${
                              theme === "dark"
                                ? "dark-mode "
                                : theme === "high-contrast"
                                ? "high-contrast  "
                                : ""
                            }`}
                          >
                            {task.accountNo}
                          </span>
                        )}
                      </dd>
                    </div>
                    <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-4">
                      <dt
                        className={`text-sm font-semibold text-gray-700 ${
                          theme === "dark"
                            ? "dark-mode "
                            : theme === "high-contrast"
                            ? "high-contrast  "
                            : ""
                        }`}
                      >
                        Activity
                      </dt>
                      <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">
                        {editMode ? (
                          <input
                            type="text"
                            className={`w-full border border-gray-300 focus:border-blue-500
                               focus:ring-2 focus:ring-blue-200 rounded-md p-2 ${
                                 theme === "dark"
                                   ? "dark-mode "
                                   : theme === "high-contrast"
                                   ? "high-contrast  "
                                   : ""
                               }`}
                            value={taskDetails.Activity}
                            onChange={(e) =>
                              setTask({
                                ...task,
                                taskDetails: {
                                  ...taskDetails,
                                  Activity: e.target.value,
                                },
                              })
                            }
                            disabled
                          />
                        ) : (
                          <span
                            className={`inline-block rounded-md px-3 py-1 text-gray-700 ${
                              theme === "dark"
                                ? "dark-mode "
                                : theme === "high-contrast"
                                ? "high-contrast  "
                                : ""
                            }`}
                          >
                            {taskDetails.Activity}
                          </span>
                        )}
                      </dd>
                    </div>
                    <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-4">
                      <dt
                        className={`text-sm font-semibold text-gray-700 ${
                          theme === "dark"
                            ? "dark-mode "
                            : theme === "high-contrast"
                            ? "high-contrast  "
                            : ""
                        }`}
                      >
                        Priority
                      </dt>
                      <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">
                        {editMode ? (
                          <input
                            type="text"
                            className={`w-full border border-gray-300 focus:border-blue-500
                               focus:ring-2 focus:ring-blue-200 rounded-md p-2 ${
                                 theme === "dark"
                                   ? "dark-mode "
                                   : theme === "high-contrast"
                                   ? "high-contrast  "
                                   : ""
                               }`}
                            value={task.priority}
                            onChange={handlePriorityChange}
                          />
                        ) : (
                          <span
                            className={`inline-block rounded-md px-3 py-1 text-gray-700 ${
                              theme === "dark"
                                ? "dark-mode "
                                : theme === "high-contrast"
                                ? "high-contrast  "
                                : ""
                            }`}
                          >
                            {task.priority}
                          </span>
                        )}
                      </dd>
                    </div>
                    <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-4">
                      <dt
                        className={`text-sm font-semibold text-gray-700 ${
                          theme === "dark"
                            ? "dark-mode "
                            : theme === "high-contrast"
                            ? "high-contrast  "
                            : ""
                        }`}
                      >
                        Status
                      </dt>
                      <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">
                        {editMode ? (
                          <input
                            type="text"
                            className={`w-full border border-gray-300 focus:border-blue-500
                               focus:ring-2 focus:ring-blue-200 rounded-md p-2 ${
                                 theme === "dark"
                                   ? "dark-mode "
                                   : theme === "high-contrast"
                                   ? "high-contrast  "
                                   : ""
                               }`}
                            value={task.taskStatus}
                            onChange={(e) =>
                              setTask({ ...task, taskStatus: e.target.value })
                            }
                            disabled
                          />
                        ) : (
                          <span
                            className={`inline-block rounded-md px-3 py-1 text-gray-700 ${
                              theme === "dark"
                                ? "dark-mode "
                                : theme === "high-contrast"
                                ? "high-contrast  "
                                : ""
                            }`}
                          >
                            {task.taskStatus}
                          </span>
                        )}
                      </dd>
                    </div>
                    <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-4">
                      <dt
                        className={`text-sm font-semibold text-gray-700 ${
                          theme === "dark"
                            ? "dark-mode "
                            : theme === "high-contrast"
                            ? "high-contrast  "
                            : ""
                        }`}
                      >
                        Created Date
                      </dt>
                      <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">
                        {editMode ? (
                          <input
                            type="text"
                            className={`w-full border border-gray-300 focus:border-blue-500
                               focus:ring-2 focus:ring-blue-200 rounded-md p-2 ${
                                 theme === "dark"
                                   ? "dark-mode "
                                   : theme === "high-contrast"
                                   ? "high-contrast  "
                                   : ""
                               }`}
                            value={task.createdDate}
                            onChange={(e) =>
                              setTask({
                                ...task,
                                createdDate: e.target.value,
                              })
                            }
                            disabled
                          />
                        ) : (
                          <span
                            className={`inline-block rounded-md px-3 py-1 text-gray-700 ${
                              theme === "dark"
                                ? "dark-mode "
                                : theme === "high-contrast"
                                ? "high-contrast  "
                                : ""
                            }`}
                          >
                            {task.createdDate}
                          </span>
                        )}
                      </dd>
                    </div>
                    <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-4">
                      <dt
                        className={`text-sm font-semibold text-gray-700 ${
                          theme === "dark"
                            ? "dark-mode "
                            : theme === "high-contrast"
                            ? "high-contrast  "
                            : ""
                        }`}
                      >
                        Latitude
                      </dt>
                      <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">
                        {editMode ? (
                          <input
                            type="text"
                            className={`w-full border border-gray-300 focus:border-blue-500
                               focus:ring-2 focus:ring-blue-200 rounded-md p-2 ${
                                 theme === "dark"
                                   ? "dark-mode "
                                   : theme === "high-contrast"
                                   ? "high-contrast  "
                                   : ""
                               }`}
                            value={task.latitude}
                            onChange={handleLatitudeChange}
                          />
                        ) : (
                          <span
                            className={`inline-block rounded-md px-3 py-1 text-gray-700 ${
                              theme === "dark"
                                ? "dark-mode "
                                : theme === "high-contrast"
                                ? "high-contrast  "
                                : ""
                            }`}
                          >
                            {task.latitude}
                          </span>
                        )}
                      </dd>
                    </div>
                    <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-4">
                      <dt
                        className={`text-sm font-semibold text-gray-700 ${
                          theme === "dark"
                            ? "dark-mode "
                            : theme === "high-contrast"
                            ? "high-contrast  "
                            : ""
                        }`}
                      >
                        Longitude
                      </dt>
                      <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">
                        {editMode ? (
                          <input
                            type="text"
                            className={`w-full border border-gray-300 focus:border-blue-500
                               focus:ring-2 focus:ring-blue-200 rounded-md p-2 ${
                                 theme === "dark"
                                   ? "dark-mode "
                                   : theme === "high-contrast"
                                   ? "high-contrast  "
                                   : ""
                               }`}
                            value={task.longitude}
                            onChange={handleLongitudeChange}
                          />
                        ) : (
                          <span
                            className={`inline-block rounded-md px-3 py-1 text-gray-700 ${
                              theme === "dark"
                                ? "dark-mode "
                                : theme === "high-contrast"
                                ? "high-contrast  "
                                : ""
                            }`}
                          >
                            {task.longitude}
                          </span>
                        )}
                      </dd>
                    </div>
                    <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-4">
                      <dt
                        className={`text-sm font-semibold text-gray-700 ${
                          theme === "dark"
                            ? "dark-mode "
                            : theme === "high-contrast"
                            ? "high-contrast  "
                            : ""
                        }`}
                      >
                        Area Code
                      </dt>
                      <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">
                        {editMode ? (
                          <textarea
                            className={`w-full border border-gray-300 focus:border-blue-500
                               focus:ring-2 focus:ring-blue-200 rounded-md p-2 ${
                                 theme === "dark"
                                   ? "dark-mode "
                                   : theme === "high-contrast"
                                   ? "high-contrast  "
                                   : ""
                               }`}
                            value={task.areaCode}
                            onChange={handleAreacodeChange}
                          />
                        ) : (
                          <span
                            className={`inline-block rounded-md px-3 py-1 text-gray-700 ${
                              theme === "dark"
                                ? "dark-mode "
                                : theme === "high-contrast"
                                ? "high-contrast  "
                                : ""
                            }`}
                          >
                            {task.areaCode}
                          </span>
                        )}
                      </dd>
                    </div>
                    <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-4">
                      <dt
                        className={`text-sm font-semibold text-gray-700 ${
                          theme === "dark"
                            ? "dark-mode "
                            : theme === "high-contrast"
                            ? "high-contrast  "
                            : ""
                        }`}
                      >
                        Tariff
                      </dt>
                      <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">
                        {editMode ? (
                          <textarea
                            className={`w-full border border-gray-300 focus:border-blue-500
                               focus:ring-2 focus:ring-blue-200 rounded-md p-2 ${
                                 theme === "dark"
                                   ? "dark-mode "
                                   : theme === "high-contrast"
                                   ? "high-contrast  "
                                   : ""
                               }`}
                            value={task.tariff}
                            onChange={handleTarrifChange}
                          />
                        ) : (
                          <span
                            className={`inline-block rounded-md px-3 py-1 text-gray-700 ${
                              theme === "dark"
                                ? "dark-mode "
                                : theme === "high-contrast"
                                ? "high-contrast  "
                                : ""
                            }`}
                          >
                            {task.tariff}
                          </span>
                        )}
                      </dd>
                    </div>
                    <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-4">
                      <dt
                        className={`text-sm font-semibold text-gray-700 ${
                          theme === "dark"
                            ? "dark-mode "
                            : theme === "high-contrast"
                            ? "high-contrast  "
                            : ""
                        }`}
                      >
                        Office
                      </dt>
                      <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">
                        {editMode ? (
                          <textarea
                            className={`w-full border border-gray-300 focus:border-blue-500
                               focus:ring-2 focus:ring-blue-200 rounded-md p-2 ${
                                 theme === "dark"
                                   ? "dark-mode "
                                   : theme === "high-contrast"
                                   ? "high-contrast  "
                                   : ""
                               }`}
                            value={task.office}
                            onChange={handleOfficeChange}
                          />
                        ) : (
                          <span
                            className={`inline-block rounded-md px-3 py-1 text-gray-700 ${
                              theme === "dark"
                                ? "dark-mode "
                                : theme === "high-contrast"
                                ? "high-contrast  "
                                : ""
                            }`}
                          >
                            {task.office}
                          </span>
                        )}
                      </dd>
                    </div>
                    <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-4">
                      <dt
                        className={`text-sm font-semibold text-gray-700 ${
                          theme === "dark"
                            ? "dark-mode "
                            : theme === "high-contrast"
                            ? "high-contrast  "
                            : ""
                        }`}
                      >
                        Area
                      </dt>
                      <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">
                        {editMode ? (
                          <textarea
                            className={`w-full border border-gray-300 focus:border-blue-500
                               focus:ring-2 focus:ring-blue-200 rounded-md p-2 ${
                                 theme === "dark"
                                   ? "dark-mode "
                                   : theme === "high-contrast"
                                   ? "high-contrast  "
                                   : ""
                               }`}
                            value={task.area}
                            onChange={handleAreaChange}
                          />
                        ) : (
                          <span
                            className={`inline-block rounded-md px-3 py-1 text-gray-700 ${
                              theme === "dark"
                                ? "dark-mode "
                                : theme === "high-contrast"
                                ? "high-contrast  "
                                : ""
                            }`}
                          >
                            {task.area}
                          </span>
                        )}
                      </dd>
                    </div>
                  </>
                )}
              </dl>
              {editMode && (
                <div className="flex justify-end gap-3 mt-6">
                  <button
                    className="bg-gray-100 text-sm border border-gray-300 text-gray-700 font-medium py-2 px-4 rounded-md hover:bg-gray-200"
                    onClick={handleDiscard}
                  >
                    {translations[langMode].discard}
                  </button>
                  <button
                    className="bg-blue-800 text-sm text-white font-medium py-2 px-4 rounded-md hover:bg-blue-700"
                    onClick={handleConfirm}
                  >
                    {translations[langMode].Confirm}
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="w-full px-4 ">
            <div className="px-4 sm:px-0 flex ">
              <h3
                className={`  ${
                  theme === "dark"
                    ? "dark-mode"
                    : theme === "high-contrast"
                    ? "high-contrast"
                    : ""
                } text-sm font-semibold leading-7 text-gray-900`}
              >
                {translations[langMode].taskhistory}
              </h3>
            </div>
            <div
              id="accordion"
              className="my-5 custom-scrollbar"
              style={{ height: "1100px", overflowY: "scroll" }}
              onScroll={handleScroll} // Attach here
            >
              {taskHistory.length === 0 ? (
                <div className="text-center text-gray-500">
                  {translations[langMode].nohistory}
                </div>
              ) : (
                taskHistory.map((task, index) => (
                  <div key={index} className="mb-6">
                    <div
                      className={`cursor-pointer bg-gray-100 border border-blue-300 p-4 rounded-lg 
                        shadow-md flex justify-between items-center hover:bg-blue-100 transition-colors duration-300  ${
                          theme === "dark"
                            ? "dark-mode"
                            : theme === "high-contrast"
                            ? "high-contrast"
                            : ""
                        }`}
                      onClick={() => handleToggle(index)}
                    >
                      <h5
                        className={` ${
                          theme === "dark"
                            ? "dark-mode"
                            : theme === "high-contrast"
                            ? "high-contrast"
                            : ""
                        } text-gray-900 text-base font-semibold`}
                      >
                        user {task.userId} - {task.heading}
                      </h5>
                      <div className="flex items-center gap-2">
                        <h5
                          className={`text-gray-600 text-sm  ${
                            theme === "dark"
                              ? "dark-mode"
                              : theme === "high-contrast"
                              ? "high-contrast"
                              : ""
                          }`}
                        >
                          {formatDate(task.createdAt)}
                        </h5>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className={`w-5 h-5 transform transition-transform duration-300 ${
                            expandedTask === index ? "rotate-180" : "rotate-0"
                          }  ${
                            theme === "dark"
                              ? "dark-mode"
                              : theme === "high-contrast"
                              ? "high-contrast"
                              : ""
                          }`}
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className={`overflow-hidden transition-all duration-500 ease-in-out ${
                        expandedTask === index ? "max-h-screen" : "max-h-0"
                      }`}
                    >
                      <div
                        className={`mt-3 p-4 rounded-md bg-gray-100 shadow-inner border border-gray-300  ${
                          theme === "dark"
                            ? "dark-mode"
                            : theme === "high-contrast"
                            ? "high-contrast"
                            : ""
                        }`}
                      >
                        <div className="flex gap-4 items-center">
                          {isValidURL(task.body) ? (
                            <img
                              src={task.body}
                              alt="Task Image"
                              className="max-w-full h-44 rounded-md shadow-sm border-gray-400"
                            />
                          ) : (
                            <p
                              className={`text-gray-700  ${
                                theme === "dark"
                                  ? "dark-mode"
                                  : theme === "high-contrast"
                                  ? "high-contrast"
                                  : ""
                              }`}
                            >
                              {task.body}
                            </p>
                          )}
                        </div>
                        <div
                          className={`mt-4 text-sm text-gray-600  ${
                            theme === "dark"
                              ? "dark-mode"
                              : theme === "high-contrast"
                              ? "high-contrast"
                              : ""
                          }`}
                        >
                          {task.comment}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
              {isLoading && (
                <div className="text-center text-gray-500">
                  {translations[langMode].more}
                </div>
              )}
              {!hasMore && (
                <div className="text-center text-gray-500">
                  {translations[langMode].nohistory}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TaskDetailPage;
