import React, { useState } from "react";
import { AiFillSound } from "react-icons/ai";
import { DragDropFile } from "../../common/DragDropFile";

const ConfigurationSetting = () => {

  const [notificationVolume, setNotificationVolume] = useState(() => {
    // Load saved volume from localStorage or use default
    return parseInt(localStorage.getItem("notificationVolume")) || 50;
  });

  const handleVolumeChange = (e) => {
    const volume = parseInt(e.target.value);
    setNotificationVolume(volume);
    localStorage.setItem("notificationVolume", volume); // Save to localStorage
  };

  const playDefaultSound = () => {
    const audio = new Audio("/sound/notification.mp3");
    audio.volume = notificationVolume / 100; // Set volume
    audio.play();
  };

  return (
    <>
      <div className="py-5">
        <h3 className="text-sm text-gray-500 font-bold">Notification Sound</h3>
        <div className="flex items-center p-2 gap-2">
          <AiFillSound />
          <input
            type="range"
            min="0"
            max="100"
            value={notificationVolume}
            onChange={handleVolumeChange}
            className="w-60"
          />
          <button
            onClick={playDefaultSound}
            className="bg-blue-500 text-white px-4 py-1 rounded"
          >
            Test Sound
          </button>
        </div>
      </div>
      <div>
        <h3 className="text-sm text-gray-500 font-bold">Configurations</h3>
        <div className="flex items-center mt-5 gap-6">
          <div>
            <span className="text=sm text-gray-400">Terms and Conditions</span>
            <DragDropFile
              filelabel={"Drag and drop Terms and Condition file"}
            />
          </div>
          <div>
            <span className="text=sm text-gray-400">Privacy Acceptance</span>
            <DragDropFile filelabel={"Drag and drop Privacy Acceptance file"} />
          </div>
        </div>
        <div className="bg-gray-100 w-80 p-4 flex items-center gap-6">
          <span className="text-sm font-semibold text-gray-500">
            TermsandCondition.pdf
          </span>
          <span className="text-sm underline font-semibold text-blue-500">
            view
          </span>
        </div>
      </div>
    </>
  );
};

export default ConfigurationSetting;
